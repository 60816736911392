<template>
  <div class="text-center" id="appBarMenu">
    <v-menu :close-on-content-click="false" offset-y transition="slide-y-transition" bottom>
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn v-bind="attrs" v-on="on" text  class="overflow-hidden" > -->

        <div v-bind="attrs" v-on="on" class="d-flex px-2  rounded-xl align-center">
          <v-btn icon fab x-small color="white" class="secondary"><v-icon size="24">mdi-chevron-down</v-icon></v-btn>
        </div>

        <!-- </v-btn> -->
      </template>

      <v-card width="300" class="py-0 mt-5 rounded-lg">
        <v-list-item>
          <v-list-item-content class="justify-center">
            <div class="d-flex  rounded-xl px-5 align-center">
              <img src="@/assets/img/pos_svg/branch.svg" class="mx-2" height="23" alt="Qawaem-POS">
              <div class="text-start">
                <div class="body-2 gray8--text">{{ $t("branch") }}</div>
                <span class="font-weight-bold caption">{{ activeBranch || $t("not available") }}</span>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list-item @click="barberBookingDialog = true">
          <v-list-item-content class="justify-center">
            <div class="d-flex  rounded-xl px-5 align-center cursor_pointer" style="height: 44px;"
              >
              <img src="@/assets/img/pos_svg/scissors.svg" class="mx-2" height="23" alt="Qawaem-POS">
              <div class="text-start">
                <span class="font-weight-bold caption">{{ $t("barber booking") | capitalize }}</span>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list-item @click="$router.push('/close-sift')">
          <v-list-item-content class="justify-center">
            <div class="d-flex  rounded-xl px-5 align-center cursor_pointer">
              <img src="@/assets/img/pos_svg/endSheeft.svg" class="mx-2" height="35" alt="Qawaem-POS">
              <div class="text-start">
                <div class="body-2  gray8--text">{{ '2022-08-25' || $t("not available") }}</div>
                <span class="font-weight-bold caption">{{ $t("close shift") }}</span>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list-item @click="changeLanguage">
          <v-list-item-content class="justify-center">
            <div class="d-flex  rounded-xl px-5 align-center cursor_pointer" style="height: 44px;">
              <img src="@/assets/img/svg/USA.svg" height="25" class="mx-2" v-if="$vuetify.rtl == true" alt="Qawaem-POS" />
              <img src="@/assets/img/svg/KSA.svg" height="25" class="mx-2" v-if="$vuetify.rtl == false" alt="Qawaem-POS" />
              <div class="text-start">
                <span class="font-weight-bold caption">{{ $t("change language") | capitalize }}</span>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
        <v-divider/>
        <v-list-item @click="$api.LOGOUT(true)">
          <v-list-item-content class="justify-center">
            <div class="d-flex  rounded-xl px-5 align-center cursor_pointer" style="height: 44px;"
              >
              <img src="@/assets/img/pos_svg/logout.svg" class="mx-2" height="23" alt="Qawaem-POS">
              <div class="text-start">
                <span class="font-weight-bold caption">{{ $t("logout") | capitalize }}</span>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>

      </v-card>
    </v-menu>
  </div>
</template>
<style scoped>
.v-menu__content {
  box-shadow: 0px 21px 32px rgba(0, 0, 0, 0.05) !important;
  top: 3rem !important;
  border-radius: 0px 0px 8px 8px;
}

#appBarMenu .v-menu__content {
  top: 64px !important;
  box-shadow: none !important;
}

#appBarMenu.v-menu__content .v-card {
  background-color: #fafafa !important;
}

.userList {
  cursor: pointer;
}
</style>

<script>
// import ChangePasswordModalVue from "../modals/ChangePasswordModal.vue";
export default {
  name: "UserMenu",
  computed: {

  },
  components: {
    //  ChangePasswordModalVue 
  },
  data: () => ({
    user: {},
    login_date: null,
    activeBranch: null,
    changePassDialog: false,
  }),
  mounted() {
    this.login_date = localStorage.getItem("login_date");
  },

  methods: {
    close(status) {
      this.changePassDialog = status;
    },
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
  },
};
</script>
