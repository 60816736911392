<template>
  <section>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" md="6" lg="3">
          Debtor Notice Control
        </v-col>
      </v-row>
      
    </v-container>
  </section>
</template>

<script>



export default {
  name: "DebtorNoticeControl",
  computed: {

  },
  components: {

  },

  data: () => ({
    // lockups

    // page data
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {

    },
    pageMainData() {
 
    },

    getData() {


    },

  },
};
</script>
