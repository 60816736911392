<template>
  <section>
    <v-container fluid>
      <v-card class="shadow-none ">

        <v-col cols="12" v-if="isLoading">
          <AnimatedLoading classes="pt-2 my-15 pa-5" :isLoading="isLoading" />
        </v-col>

        <v-form ref="form" v-model="valid" class="pa-5" v-if="!isLoading">
          <v-sheet class="backgroundW  pa-5">
            <v-row justify="space-between" class="justify-xl-space-around py-4 py-xl-6">


              <v-col cols="12" md="auto">
                <v-sheet max-width="400" class="d-block mt-n3 ma-auto">
                  <label>{{ $t('amount') }}*</label>
                  <NumberPad :backValueMethod="setAmount" :value="form.amount" />
                </v-sheet>
              </v-col>

              <v-col cols="auto" class="d-none py-16 d-md-block">
                <v-divider vertical style="height: 180px ;"></v-divider>
              </v-col>
              <v-col cols="12" md="4" lg="7" xl="6">
                <v-row align="center">

                  <v-col cols="12" sm="6" md="12" lg="4">
                    <label>{{ $t('pay for') }}*</label>

                    <v-text-field v-model="form.pay_for" :rules="$store.state.Settings.requiredRule" class="my-2"
                      background-color="#f4f5f7" solo :loading="isLoading" rounded-md :no-resize="true" dense
                      :rows="4" />

                  </v-col>

                  <v-col cols="12" sm="6" md="12" lg="4" class="py-0">
                    <label>{{ $t('choose an expenses code') }}*</label>
                    <v-autocomplete :items="accounts" item-text="name_ar" solo class="my-2" item-value="id"
                      :rules="$store.state.Settings.requiredRule" v-model="form.expense_code_id"
                      :no-data-text="$i18n.t('no selects')" dense clearable rounded-md background-color="#f4f5f7"
                      :loading="isLoading" />
                  </v-col>

                  <v-col cols="12" sm="6" md="12" lg="4" class="py-0">
                    <label>{{ $t('date') }}*</label>

                    <v-menu v-model="datePicker" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="form.action_date" append-icon="mdi-calendar"
                          @click:append="datePicker = true" readonly v-bind="attrs" v-on="on" :disabled="disabled" solo
                          class="my-2" background-color="#f4f5f7" :rules="$store.state.Settings.requiredRule"
                          :loading="isLoading" rounded-md dense />
                      </template>
                      <v-date-picker color="primary" :locale="$vuetify.rtl == true ? 'ar' : 'en-UK'"
                        v-model="form.action_date" @input="datePicker = false" :max="today">
                      </v-date-picker>
                    </v-menu>

                  </v-col>

                  <v-col cols="12">
                    <label>{{ $t('description') }}*</label>

                    <v-textarea v-model="form.description" :rules="$store.state.Settings.requiredRule" class="my-2"
                      background-color="#f4f5f7" solo :loading="isLoading" rounded-md :no-resize="true" dense
                      :rows="4" />

                  </v-col>

                  <v-col cols="12" class="py-0">
                    <v-btn depressed color="success" @click="save" :loading="saveIsLoading"
                      :disabled="!(valid && Number(this.form.amount))" width="130" height="42"
                      class="d-block mi-start-auto">
                      {{ $t('create invoice') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-sheet>
        </v-form>


      </v-card>

    </v-container>
  </section>
</template>



<script>
import NumberPad from "@/components/NumberPad.vue";
import AnimatedLoading from "@/components/AnimatedLoading.vue";
export default {
  name: "ExpensesControl",
  computed: {

  },
  data: () => ({
    valid: false,
    isLoading: false,
    saveIsLoading: false,
    datePicker: false,
    accounts: [],
    key: 1,
    form: {
      expense_code_id: null,
      amount: '',
      action_date: null,
      description: null,
      pay_for: null,
    },
    today: null,

  }),
  components: {
    NumberPad,
    AnimatedLoading
},
  watch: {
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F5':
          this.getData();
          break;
        case 'F10':
          if(this.valid){
            this.save()
          }
          break;
        default:
          break;
      }
    },
  },
  mounted() { this.formatDate(); this.getData() },
  methods: {
    getData() {
      this.isLoading = true;
      this.$api
        .GET_METHOD(`expense/create`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.accounts = response.data.expenses_codes
          }
        });
    },
    save() {
      this.saveIsLoading = true;
      this.$api.POST_METHOD(`expense`, this.form , true).then((response) => {
        this.saveIsLoading = false;
        if (response.check) {
          const src = `src="${this.$api.serverUrl}export_expense/${response.data.id}?type=image&clientID=${this.$store.state.clientID}"`;
          this.$api.PrintInvoice(src, response.data.id)
          this.form = {
            expense_code_id: null,
            amount: '',
            action_date: null,
            description: null,
          };
          this.formatDate()
        }
      })
    },
    setAmount(returnedAmount) {
      this.form.amount = returnedAmount;
    },
    formatDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.today = [year, month, day].join('-');
      this.form.action_date = [year, month, day].join('-');
    },
  },
};
</script>

