<template>
  <section>
    <v-container fluid>
      <!-- Statistics -->
      <v-row align="center" justify="center">
        <v-col cols="11" sm="6" lg="3" class="py-1">
          <v-card height="100" class="shadow d-flex align-center justify-space-around pa-2 rounded-lg">
            <div class="light pa-3 rounded-0">
              <img src="@/assets/img/pos_svg/purchase.svg" height="40" width="40" alt="qawaem" />
            </div>
            <div class="mx-5">
              <div class="subtitle-1">{{ $t('purchase cash') | capitalize }}</div>
              <div class="subtitle-1 font-weight-bold">{{ cash_totals | float }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="11" sm="6" lg="3" class="py-1">
          <v-card height="100" class="shadow d-flex align-center justify-space-around pa-2 rounded-lg">
            <div class="light pa-3 rounded-0">
              <img src="@/assets/img/pos_svg/network.svg" height="40" width="40" alt="qawaem" />
            </div>
            <div class="mx-5">
              <div class="subtitle-1">{{ $t('total sales network') | capitalize }}</div>
              <div class="subtitle-1 font-weight-bold">{{ bank_totals | float }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="11" sm="6" lg="3" class="py-1">
          <v-card height="100" class="shadow d-flex align-center justify-space-around pa-2 rounded-lg">
            <div class="light pa-3 rounded-0">
              <img src="@/assets/img/pos_svg/orders.svg" height="40" width="40" alt="qawaem" />
            </div>
            <div class="mx-5">
              <div class="subtitle-1">{{ $t('total requests') | capitalize }}</div>
              <div class="subtitle-1 font-weight-bold">{{ sales_invoices_count }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="11" sm="6" lg="3" class="py-1">
          <v-card height="100" class="shadow d-flex align-center justify-space-around pa-2 rounded-lg">
            <div class="light pa-3 rounded-0">
              <img src="@/assets/img/pos_svg/pending_orders.svg" height="40" alt="qawaem" />
            </div>
            <div class="mx-5">
              <div class="subtitle-1">{{ $t('pending requests') | capitalize }}</div>
              <div class="subtitle-1 font-weight-bold">{{ sales_orders_count }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" class="py-1">
          <v-card class="shadow py-2 rounded-md rounded-lg overflow-hidden">
            <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 200" :viewTitle="true"
              :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
              :option="pageData.options" tableTitle="daily requests" :pageData="pageData">

              <template v-slot:inputs>
                <v-row class="px-3" align="center">
                  <v-col cols="12" sm="9">

                    <v-text-field style="border: 1px solid #e6e6e6" class="rounded-lg" background-color="white" v-model="pageData.search"
                      @keydown.enter="(e) => getSearch(e.target.value)" @click:clear="(e) => getSearch(e.target.value)"
                      @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :placeholder="
                        pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('search')
                      " single-line clearable hide-details solo dense :height="37"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-btn block depressed :loading="isLoading" @click="getData()" :height="37" :width="100"
                      color="success" class="rounded-lg">
                      {{ $t("search") }}
                    </v-btn>
                  </v-col>

                </v-row>
              </template>

              <template v-slot:td="{ row, header }">
                <div v-if="header.key == 'actions'">
                  <v-btn @click="print(row)" color="success" x-small icon fab>
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                  <v-btn @click="showInvoice(row)" color="secondary" x-small icon fab>
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </div>
              </template>

            </DynamicTable>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" v-if="!pageData.isLoading && pageData.rows.length > 0">
          <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
            :isLoading="pageData.isLoading"></Pagination>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import DynamicTable from '@/components/DynamicTable.vue';
import Pagination from '@/components/Pagination.vue';


export default {
  name: "POSHome",
  computed: {

  },
  components: {
    DynamicTable,
    Pagination
  },

  data: () => ({
    pageData: {
      screen_code: "01-001",
      url: '/',
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    isLoading: false,
    cash_totals: 0,
    sales_orders_count: 0,
    sales_invoices_count: 0,
    bank_totals: 0,
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    }
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 10;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.tableHeader = [
        { text: "request", key: "m_id", type: 'text', classes: "" },
        { text: "date", key: "sales_invoice_date", type: 'text', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "item count", key: "items_count", type: 'text', classes: "" },
        { text: "cost", key: "total_with_vat_after_discount", type: 'float', classes: "" },
        { text: "actions", key: "actions", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      this.pageData.isLoading = true;


      this.$api
        .GET_METHOD(`get_home_data${this.pageData.queryParam}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.cash_totals = response.data.cash_totals  || 0;
            this.bank_totals = response.data.bank_totals || 0;
            this.sales_invoices_count = response.data.sales_invoices_count || 0;
            this.sales_orders_count = response.data.sales_orders_count || 0;
            this.pageData.rows = response.data.daily_requests.data || [];
            this.pageData.page = response.data.daily_requests.current_page;
            this.pageData.pagination = {
              page: response.data.daily_requests.current_page,
              totalPages: response.data.daily_requests.last_page,
              per_page: response.data.daily_requests.per_page,
              totalRows: response.data.daily_requests.total,
            };

          }
        });
    },
    print() {
      const src = `src="${this.$api.serverUrl}export_sales_invoice/${this.master.m_id}?type=image&clientID=${this.$store.state.clientID}"`;
      this.$api.PrintInvoice(src, this.master.m_id)
    },
    showInvoice(row) {
      this.$router.push({
        path: `/requests-control/${row.m_id}`,
        query: { m_id: row.m_id, prevScreenQueryParam: this.pageData.queryParam, prevRoute: '/' },
      });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?rows=${limit}&page=${page}&word=${this.pageData.search}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
  },
};
</script>
