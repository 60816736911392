<template>
  <section>
    <v-container fluid>

      <v-row align="center" justify="center">
        <v-col cols="12" class="py-0">

          <v-col cols="10" class="ma-auto" v-if="!report_type">
            <v-card class="elevation-0 px-5 pb-1 rounded-lg" color="white">
              <v-row align="center" justify="space-between">
                <v-col cols="auto" class="d-flex">
                  <div class=" d-flex align-center  mx-2">
                    <img src="@/assets/img/pos_svg/back arrow.svg" class="light rounded-pill pa-2" height="40"
                      alt="qawaem" />
                  </div>
                  <div class="font-weight-bold">
                    <div class="text-h6 mt-1">{{ $t('reports') }}</div>
                  </div>
                </v-col>

                <v-col cols="12" md="8">
                  <GenericInput type="select" :clearable="true" :lookups="types" selected_label="name"
                    selected_prop="id" :value="report_type" @input="report_type = $event" label="choose report"
                    :isLoading="false" :cols="[12, 12, 12]" />
                </v-col>

              </v-row>
            </v-card>
          </v-col>

          <v-card class="elevation-0 px-0 mt-3 rounded-md" color="transparent" v-if="report_type">
            <v-row class="px-5" justify="space-between mb-2">
              <v-col cols="6" class="d-flex">
                <div class=" d-flex align-center cursor_pointer mx-2" @click="$router.push('/reports')">
                  <img src="@/assets/img/pos_svg/back arrow.svg" class="light rounded-pill pa-2" height="40"
                    alt="qawaem" />
                </div>
                <div class="font-weight-bold">
                  <div class="text-h6 mt-1">{{ $t(report_type_title) }}</div>
                </div>
              </v-col>

              <v-col cols="6">
                <v-row justify="end">
                  <v-col cols="12" md="auto">
                    <v-text-field background-color="white" v-model="pageData.search" 
                      @keydown.enter="(e) => getSearch(e.target.value)" @click:clear="(e) => getSearch(e.target.value)"
                      @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :placeholder="
                        pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('search')
                      " single-line clearable hide-details outlined dense :height="37"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-btn block depressed :loading="pageData.isLoading" @click="getData()" :height="37" :width="100"
                      color="success" class="rounded-lg">
                      {{ $t("search") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 200" :viewTitle="true"
              :isLoading="pageData.isLoading" :data="pageData.rows" noDataText="no reports available" :header="pageData.tableHeader"
              :option="pageData.options" :pageData="pageData">
            </DynamicTable> -->

            <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 200" :viewTitle="false"
              :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
              :option="pageData.options" tableTitle="reports" noDataText="no reports available" :pageData="pageData">
              <template v-slot:td="{ row, header }">
                <div v-if="header.key == 'actions'">
                  <v-btn @click="print(row)" x-small icon fab>
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                  <v-btn @click="showInvoice(row)" v-if="report_type==1" x-small icon fab>
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </div>
              </template>
            </DynamicTable>

            <v-row v-if="!pageData.isLoading">
              <v-col cols="12" sm="12">
                <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
                  :isLoading="pageData.isLoading"></Pagination>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>

import DynamicTable from '@/components/DynamicTable.vue';
import GenericInput from '@/components/GenericInput.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: "ReportEntity",
  computed: {

  },
  components: {
    DynamicTable,
    GenericInput,
    Pagination
  },

  data: () => ({
    pageData: {
      screen_code: "06-008",
      url: null,
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: false,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    report_type: null,
    types: [],
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    report_type() {
      // this.getData();
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${this.pageData.search || ''}&report_type=${this.report_type}`
      this.$router.push('reports' + (this.pageData.queryParam || ''))
      if (this.report_type) {
        this.report_type_title = this.$global.FilterArrayOfObjectElement(this.types, 'id', this.report_type, 'name');
      }
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F5':
        if (this.report_type) {
          this.getData();
        }
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.queryParams();
    this.pageMainData();
    // this.getData();
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 10;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.report_type = Number(this.$store.state.activeScreen.queryParams.report_type) || ''
      if (this.report_type) {
        this.getData()
      }
    },
    pageMainData() {
      this.pageData.entityName = this.$i18n.t('report');
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
      this.types = [{ name: this.$i18n.t('sales'), id: 1 }, { name: this.$global.FilterPermissions('06-010').sub_title, id: 2 }, { name: this.$global.FilterPermissions('06-011').sub_title, id: 3 }]
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}&report_type=${this.report_type}`
      this.$router.push('reports' + (this.pageData.queryParam || ''))
    },
    getData() {
      this.pageData.isLoading = true;
      var requestBody = {
        page: this.pageData.page,
        rows: this.pageData.rowsNumber,
        word: this.pageData.search,
        report_type: this.report_type,
      }
      this.$api
        .POST_METHOD(`generate_pos_report`, requestBody)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            if (this.report_type == 1) {
              this.pageData.tableHeader = [
                { text: "request", key: "m_id", type: 'text', classes: "" },
                { text: "date", key: "sales_invoice_date", type: 'text', classes: "" },
                { text: "customer", key: "customer_name", type: 'text', classes: "" },
                { text: "mobile", key: "customer_phone", type: 'text', classes: "" },
                { text: "items count", key: "items_count", type: 'text', classes: "" },
                { text: "payment type", key: "payment_type_title", type: 'text', classes: "" },
                { text: "cost", key: "total_with_vat_after_discount", type: 'float', classes: "" },
                { text: "actions", key: "actions", type: 'slot', classes: "" },
              ];
            } else {
              this.pageData.tableHeader = [
                { text: "invoice number", key: "m_id", type: 'text', classes: "" },
                { text: "invoice date", key: "invoice_date", type: 'text', classes: "" },
                { text: "sales invoice number", key: "sales_invoice_m_id", type: 'text', classes: "" },
                { text: "customer", key: "customer_name", type: 'text', classes: "" },
                { text: "mobile", key: "customer_phone", type: 'text', classes: "" },
                { text: "actions", key: "actions", type: 'slot', classes: "" },
                // { text: "items count", key: "items_count", type: 'text', classes: "" },
                // { text: "payment type", key: "payment_type_title", type: 'text', classes: "" },
                // { text: "cost", key: "total_with_vat_after_discount", type: 'float', classes: "" },
              ];
            }
            this.pageData.rows = response.data.report.data;
            this.pageData.page = response.data.report.current_page;
            this.pageData.pagination = {
              page: response.data.report.current_page,
              totalPages: response.data.report.last_page,
              per_page: response.data.report.per_page,
              totalRows: response.data.report.total,
            };

          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search}&report_type=${this.report_type}`
      this.$router.push('reports' + (this.pageData.queryParam || ''))
    },
    print(row) {
      let src = '';
      switch (this.report_type) {
        case 1:
          src = `src="${this.$api.serverUrl}export_sales_invoice/${row.m_id}?type=image&clientID=${this.$store.state.clientID}"`;
          break;
        case 2:
          src = `src="${this.$api.serverUrl}export_notice_debtor/${row.m_id}?type=image&clientID=${this.$store.state.clientID}"`;
          break;
        case 3:
          src = `src="${this.$api.serverUrl}export_notice_creditor/${row.m_id}?type=image&clientID=${this.$store.state.clientID}"`;
          break;

        default:
          break;
      }
      this.$api.PrintInvoice(src, row.m_id)
    },
    showInvoice(row) {
      switch (this.report_type) {
        case 1:
          this.$router.push({
            path: `/requests-control/${row.m_id}`,
            query : { m_id: row.m_id , prevScreenQueryParam :  this.pageData.queryParam ,prevRoute: '/reports'},
          });
          break;
        case 2:
          this.$router.push({
            path: `/notice-debtor/${row.m_id}`
          });
          break;
        case 3:
          this.$router.push({
            path: `/notice-creditor/${row.m_id}`
          });
          break;

        default:
          break;
      }
    },
  },

};
</script>
