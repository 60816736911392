<template>
  <v-container fluid class="bgAuth d-flex align-center justify-center pa-0" style="height: 100vh">
    <v-col cols="11" lg="4" md="6" sm="7">
      <v-card v-if="!startShift" class="login-card">
        <div class="header">
          <img src="@/assets/img/png/theconceptHr.png" alt="TheConcept-POS" />
        </div>
        <v-col cols="11" class="ma-auto mt-n6 rounded-lg d-block white">
          <h3 class="text-center mt-2 mb-5" v-if="activeStep == 1">{{ $t("Forget Password") }}</h3>
          <h3 class="text-center mt-2 mb-5" v-if="activeStep == 2">{{ $t("Enter confirmation code") }}</h3>
          <h3 class="text-center mt-2 mb-5" v-if="activeStep == 3">{{ $t("enter new password") }}</h3>
          <v-form ref="form" class="row align-items-center justify-end flex-row" v-if="activeStep == 1" v-model="valid"
            lazy-validation>
            <v-col cols="12" class="pb-0 px-6">
              <h5 class="mb-3">{{ $t("company number") }}</h5>
              <v-text-field hide-details="auto" autofocus class="rounded-lg" background-color="#e8f0fe" filled
                :rules="companyIDRule" v-model="companyNumber" rounded dense @keydown.enter="next()"
                prepend-inner-icon="mdi-shield-check" type="text"></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 px-6">
              <h5 class="mb-3">{{ $t("userName") }}</h5>
              <v-text-field hide-details="auto" class="rounded-lg" background-color="#e8f0fe" filled
                :rules="usernameRule" v-model="username" rounded @keydown.enter="next()" dense
                prepend-inner-icon="mdi-account-check" type="text"></v-text-field>
            </v-col>
            <!-- <v-col cols="12" class="px-6">
              <h5 class="mb-3">{{ $t("mobile number") }}</h5>
              <v-text-field hide-details="auto" class="rounded-lg" background-color="#e8f0fe" filled
                :rules="$store.state.Settings.mobileRule" v-model="mobile" rounded @keydown.enter="next()" dense
                prepend-inner-icon="mdi-phone" type="number"></v-text-field>
            </v-col> -->
            <v-col cols="6" sm="6" class=" px-4 pa-0 d-flex align-items-center">
              <v-btn plain height="auto" color="primary--text" to="/login">
                {{ $t("login") }} {{ $t("?") }}
              </v-btn>
            </v-col>
            <v-col cols="6" class="py-5 px-6" sm="6">
              <v-btn @click="next" :min-width="100" :loading="loading" :disabled="!valid"
                class="d-block mi-start-auto shadow" color="secondary">{{ $t("next") }}</v-btn>
            </v-col>
          </v-form>


          <!-- Step 2 -->
          <div v-if="activeStep == 2">
            <div class="d-flex mb-5 justify-center">
              <v-otp-input ref="otpInput" input-classes="otp-input" separator=" " :num-inputs="4"
                :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange"
                @on-complete="handleOnComplete" />
            </div>
            <v-row align="center" class="mb-0" justify="center">
              <v-col cols="6" sm="6" class=" px-4 pa-0 d-flex align-items-center">
                <v-btn plain height="auto" color="primary--text" to="/login">
                  {{ $t("login") }} {{ $t("?") }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="px-6" sm="6">
                <v-btn @click="next" :min-width="100" :loading="loading" :disabled="!(otp.length == 4)"
                  class="d-block ma-auto shadow" color="secondary">{{ $t("next") }}</v-btn>
              </v-col>
            </v-row>
          </div>
          <!-- Step 3 -->
          <div v-if="activeStep == 3">
            <v-col cols="12" class="px-6">
              <h5 class="mb-3">{{ $t("new password") }}</h5>
              <v-text-field hide-details="auto" autofocus name="password" class="rounded-lg" background-color="#e8f0fe"
                filled required :rules="passwordRule" v-model="new_password" rounded dense
                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                prepend-inner-icon="mdi-account-key" @click:append="show = !show">
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-6">
              <h5 class="mb-3">{{ $t("confirm password") }}</h5>
              <v-text-field hide-details="auto" :name="confirm_password" class="rounded-lg" background-color="#e8f0fe"
                filled required :rules="passwordRule" v-model="confirm_password" rounded dense
                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'" :type="show ? 'text' : 'password'"
                prepend-inner-icon="mdi-account-key" @click:append="show = !show">
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-6" sm="12">
              <v-btn @click="save" :min-width="100" :loading="loading" :disabled="!passwordsMatched"
                class="d-block ma-auto shadow" color="secondary">{{ $t("save") }}</v-btn>
            </v-col>
          </div>
        </v-col>
      </v-card>
      <!-- <StartShift v-if="startShift" :branches="branches"></StartShift> -->
    </v-col>
  </v-container>
</template>

<script>
// import StartShift from "../../components/modals/StartShift.vue";
export default {
  name: "ForgetPassword",
  computed: {
    passwordsMatched() {
      if (this.new_password == this.confirm_password) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    companyNumber() {
      this.$store.commit('UPDATE_CLIENT', this.companyNumber || null)
    },
  },
  mounted() {
    document.title = this.$i18n.t("TheConcept - Forget Password");
  },
  components: {
    // StartShift
  },
  data() {
    return {
      show: false,
      loading: false,
      startShift: false,
      branches: [],
      valid: false,
      companyNumber: null,
      username: null,
      mobile: null,
      activeStep: 1,
      otp: "0",
      new_password: null,
      confirm_password: null,
      passwordRule: [
        v => !!v || this.$i18n.t('Password is required'),
      ],
      usernameRule: [
        v => !!v || this.$i18n.t('Username is required')
      ],
      companyIDRule: [
        v => !!v || this.$i18n.t('Company number is required'),
        v => (v && v.length >= 3 && v.length <= 5) || this.$i18n.t('Company ID not valid'),
      ],
    }
  },
  methods: {
    next() {
      this.loading = true;
      this.$refs.form.validate()


      if (this.valid == true && this.$store.state.clientID) {
        const requestBody = {
          client_id: this.companyNumber,
          username: this.username,
        }
        this.$api.POST_METHOD('forget_password', requestBody).then((response) => {
          this.loading = false;
          if (response.check) {
            this.activeStep = 2;
          }
        })
      }
      else {
        this.loading = false;
      }
    },
    handleOnComplete(value) {
      this.loading = true;
      const requestBody = {
        client_id: this.companyNumber,
        username: this.username,
        otp: value,
      }
      this.$api.POST_METHOD('check_otp', requestBody).then((response) => {
        this.loading = false;
        if (response.check) {
          this.activeStep = 3;
        }
      })
    },
    handleOnChange(value) {
      this.otp = value;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },
    save() {
      this.loading = true;
      const requestBody = {
        client_id: this.companyNumber,
        username: this.username,
        otp: this.otp,
        new_password: this.new_password,
      }
      this.$api.POST_METHOD('change_forget_password', requestBody).then((response) => {
        this.loading = false;
        if (response.check) {
          this.startShift = true
          this.branches = response.data.user.branchs
          this.$api.SET_AUTH(response.data.data)
        }
      })
    },
  },

};
</script>

<style scoped lang="scss">
.bgAuth {
  background-image: url("../../assets/img/png/barberBG.jpg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  .login-card {
    background: #efefef;
    padding-bottom: 1.5rem;
    border-radius: 15px;
    overflow: hidden;

    .header {
      background-color: #262525;
      min-height: 176px;
      border-radius: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
