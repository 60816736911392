<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" overlay-opacity="0.75" content-class="overflow-hidden" :max-width="width">
      <v-card relative class="backgroundW pt-6 pb-3">
        <v-btn absolute class="" :style="$vuetify.rtl == true ? 'left: 5px; top: 3px' : 'right: 5px;top: 3px'" small fab
          color="grey lighten-1" text @click="closeDialog">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>

        <v-col cols="12" class="py-5">
          <v-row justify="space-between" align="center">
            <v-col cols="2" class="text-center">
              <div class="subtitle-1 font-weight-bold">
                {{ $t('barber booking') | capitalize }}
              </div>
            </v-col>
            <v-col cols="6" class="pa-0 d-flex align-center justify-space-between">
              <v-col cols="auto"><v-btn :height="37"
                  v-if="$route.fullPath !== '/barber/barber_booking'" to="/barber/barber_booking" color="primary"
                  depressed>
                  <v-icon left>mdi-calendar</v-icon>
                  {{ $t('follow booking') }}</v-btn></v-col>
              <v-text-field background-color="light" v-model="search" outlined
                @keydown.enter="(e) => getData(e.target.value)" @click:clear="(e) => getData(e.target.value)"
                @click:append="getData(search)" append-icon="mdi-magnify" :placeholder="$i18n.t('search')
      " single-line clearable hide-details solo dense :height="37"></v-text-field>
              <v-btn depressed :loading="isLoading" @click="getData()" :height="37" :width="80" color="success"
                class="rounded-lg mx-2">
                {{ $t("search") }}
              </v-btn>
            </v-col>


            <!-- <GenericInput type="date" :value="from_date" :solo="false" :maxDate="today" @input="from_date = $event"
              prefix="to date" :required="false" :hide-details="true" :keydownEnter="getData" :isLoading="isLoading"
              :dense="true" :cols="[4, 4, 3]">
            </GenericInput> -->

          </v-row>
        </v-col>

        <v-col cols="12" class="d-block pt-0 ma-auto">
          <v-tabs active-class="primary white--text" v-model="tab">
            <v-tab>{{ $t('today') }}</v-tab>
            <v-tab>{{ $t('all') }}</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" class="d-block ma-auto">
          <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 320" cardClasses="my-0"
            :isLoading="isLoading" :data="requests" :header="tableHeader" :option="options">
            <template v-slot:td="{ row, header }">
              <div v-if="header.key == 'status'">
                <v-chip v-if="(row.barber_booking_status == 1)" color="warning" small>{{ $t('pending') }}</v-chip>
                <v-chip v-if="(row.barber_booking_status == 2)" color="success" small>{{ $t('accepted') }}</v-chip>
                <v-chip v-if="(row.barber_booking_status == 3)" color="error" small>{{ $t('rejected') }}</v-chip>
                <v-chip v-if="(row.barber_booking_status == 4)" color="success" class="white--text" small>{{
      $t('invoiced') }}</v-chip>
              </div>
              <div v-if="header.key == 'view'">
                <v-btn elevation="0" color="gray4"
                  v-if="row.barber_booking_status !== 4 && row.barber_booking_status !== 3"
                  class="white--text caption rounded-xl" @click="viewRequest(row)" height="24">
                  {{ $t("release invoice") }}
                </v-btn>
              </div>
            </template>
          </DynamicTable>
        </v-col>
        <v-col cols="11" sm="11" class="d-block  ma-auto" v-if="requests.length > 0">
          <Pagination :pagination="pagination" :limit="rows" :showRows="false" :backValueMethod="changePage"
            :isLoading="isLoading"></Pagination>
        </v-col>


      </v-card>
    </v-dialog>



    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog" :item="null"
      :backValueMethod="deleteRequestApprove" />


  </v-row>
</template>







<script>
// import GenericInput from '../GenericInput.vue';
import DeleteConfirmation from './DeleteConfirmation.vue';
import Pagination from '../Pagination.vue';
import DynamicTable from '../DynamicTable.vue';


export default {
  name: "BarberBookingModal",
  props: {
    width: { default: 1200 },
    dialog: { default: false },
    returnCloseDialog: { type: Function },
  },
  mounted() {
    this.pageMainData();
    this.from_date = this.$global.GetCurrentDate()
  },
  computed: {

  },
  data: () => ({
    deleteDialog: false,
    deleteID: null,
    isLoading: false,
    requests: [],
    from_date: null,
    search: '',
    today: null,
    pagination: null,
    page: 1,
    rows: 15,
    tab: 0,
    tableHeader: [],
    options: Object,
  }),
  methods: {

    closeDialog() {
      this.returnCloseDialog();
      this.$store.state.notificationsCount = 0
    },
    pageMainData() {
      this.tableHeader = [
        { text: "booking number", key: "id", type: 'text', classes: "" },
        { text: "customer", key: "barber_customer_name", type: 'text', classes: "" },
        { text: "mobile", key: "barber_customer_mobile", type: 'text', classes: "" },
        { text: "date", key: "barber_booking_date", type: 'date', classes: "" },
        { text: "start time", key: "start_time", type: 'text', classes: "" },
        { text: "end time", key: "end_time", type: 'text', classes: "" },
        // { text: "date", key: "add_date", type: 'date', classes: "" },
        // { text: "services count", key: "services_count", type: 'text', classes: "" },
        { text: "value", key: "total_with_vat", type: 'float', classes: "" },
        { text: "barber", key: "barber_name", type: 'text', classes: "" },
        { text: "status", key: "status", type: 'slot', classes: "" },
        { text: "", key: "view", type: 'slot', classes: "" },
      ];
    },
    getData() {
      this.isLoading = true;
      this.$api
        .GET_METHOD(`get_barber_bookings?active=1&word=${this.search || ''}&rows=${this.rows}&page=${this.page || 1}&from_date=${this.tab == 0 ? this.from_date : ''}&to_date=${this.tab == 0 ? this.from_date : ''}&status=${this.tab == 0 ? 1 : ''}`)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            this.requests = response.data.items.data;
            this.page = response.data.items.current_page;
            this.pagination = {
              page: response.data.items.current_page,
              totalPages: response.data.items.last_page,
              per_page: response.data.items.per_page,
              totalRows: response.data.items.total,
            };

          }
        });
    },
    deleteRequest(row) {
      this.deleteID = row.m_id
      this.deleteDialog = true
    },
    changePage(page, limit) {
      this.page = page;
      this.rows = limit;
      this.getData();
    },
    deleteRequestApprove(status) {
      this.deleteDialog = false;
      if (status == true) {
        this.$api.POST_METHOD(`delete_sales_order/${this.deleteID}`).then((response) => {
          if (response.check) {
            this.getData();
          }
        });
      }
    },
    viewRequest(row) {
      console.log(row);
      this.$router.push({
        name: "ReleaseBookingInvoice",
        params: { booking_data: row, id: row.id },
      });
      this.returnCloseDialog();
    },
  },
  watch: {
    dialog() {
      this.tab = 0
      if (this.dialog == true) {
        this.requests = [];
        this.today = this.$global.GetCurrentDate();
        this.from_date = this.$global.GetCurrentDate();
        if (this.isLoading == false) {
          this.getData();
          this.isLoading = true
        }
      } else {
        this.returnCloseDialog();
      }
    },
    '$route'(){
      this.returnCloseDialog();
    },
    tab() {
      this.page = 1
      this.getData();
    },
    from_date() {
      if (this.isLoading == false) {
        this.getData();
        this.isLoading = true
      }

    }
  },
  components: {
    // GenericInput,
    DeleteConfirmation,
    Pagination,
    DynamicTable
  }
};
</script>


<style scoped lang="scss">
.tdDetails {
  position: absolute;
  bottom: 12px;
  right: 18px;

  div {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding: 0 0rem;
    transition: .3s;
  }
}
</style>