<template>
   <section>
      <v-container fluid class="px-0">
         <!-- Header -->
         <v-row align="center" justify="space-between">
            <v-col cols="auto" class="py-1 px-6 font-weight-bold">
               {{ $t('new booking') }}
            </v-col>
            <v-col cols="auto" class="py-1">
               <v-btn depressed @click="save" :min-width="90" :loading="(saveIsLoading || heCanIsLoading)"
               :disabled="!(valid && heCan)" class="my-2 mx-2 rounded-lg white--text" :height="37" color="green1">{{
                  $t('save')
               }}</v-btn>
            </v-col>
         </v-row>
         <!-- End Of Header -->
         <!-- Form -->
         <v-form ref="form" v-model="valid" class="px-3">
            <v-card class="backgroundW shadow pa-5 mt-3 overflow-hidden">
               <v-row v-if="!pageData.isLoading" justify="space-around">

                  <v-col cols="12" md="3" class="px-1">
                     <div class="subtitle-2 mb-5 gray8--text">
                        {{ $t('customer details') | capitalize }}
                     </div>
                     <v-col cols="12" class="position-relative pa-0">
                        <GenericInput :dense="true" type="select" :lookups="barber_customers" paddingX="px-0"
                           :value="form.customer_mobile" @input="form.customer_mobile = $event" label="customer mobile"
                           :multi="false" :required="true" selected_label="mobile" :disabled="false" selected_prop="mobile"
                           :isLoading="pageData.editIsLoading" :cols="[12, 12, 12]"></GenericInput>

                        <v-btn icon fab x-small class="lockupsAddButton" @click="addCustomerDialog = true" color="success">
                           <v-icon>mdi-plus</v-icon>
                        </v-btn>
                     </v-col>
                     <GenericInput :dense="true" paddingX="px-0" type="text" name="customer_name" :value="form.customer_name"
                        @input="form.customer_name = $event" label="customer name" :required="true"
                        :isLoading="pageData.isLoading" :cols="[12, 12, 12]">
                     </GenericInput>
                     <GenericInput :dense="true" paddingX="px-0" type="textarea" name="notes" :value="form.notes"
                        @input="form.notes = $event" label="notes" :required="false" :isLoading="pageData.isLoading"
                        :cols="[12, 12, 12]">
                     </GenericInput>
                  </v-col>

                  <v-col cols="12" sm="auto">
                     <v-divider vertical />
                  </v-col>
                  <v-col cols="12" md="5" class="px-1">
                     <div class="subtitle-2 mb-5 gray8--text">
                        {{ $t('select services') | capitalize }}
                     </div>
                     <v-row>
                        <v-col cols="12" v-if="barber">
                           <v-sheet color="light" class="pa-3 rounded-lg">
                              <v-row align="center">
                                 <v-col cols="12" class="caption pb-0 font-weight-bold gray3--text">
                                    {{ $t('barber') | capitalize }}
                                 </v-col>
                                 <v-col cols="auto" class="caption gray3--text" v-lazy-container="{ selector: 'img' }">
                                    <img :height="45" :width="45" :data-src="$store.state.endpointURL + barber.image"
                                       class="d-block  mi-start-auto rounded-circle" :alt="barber.name" />
                                 </v-col>
                                 <v-col cols="auto" class="caption gray3--text">
                                    {{ barber.name }}
                                 </v-col>
                              </v-row>

                           </v-sheet>
                        </v-col>
                        <v-col cols="12" md="4" class="px-0">
                           <v-tabs dense show-arrows
                              :centered="!($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md')"
                              :vertical="($vuetify.breakpoint.name === 'xl' || $vuetify.breakpoint.name === 'lg' || $vuetify.breakpoint.name === 'md')">
                              <v-tabs-slider></v-tabs-slider>

                              <v-tab dense active-class="activeItem" class="font-weight-bold" :value="category.id"
                                 @click="category_id = category.category_id" v-for="(category) in categories"
                                 :key="category.category_id">
                                 <div class="d-flex w-100 justify-between align-center">
                                    <div class="d-flex  align-center">
                                       <div v-lazy-container="{ selector: 'img' }" class="d-none d-sm-block"
                                          :key="`category_img_${category.category_id}`">
                                       </div>
                                       <div class="caption mx-2">{{ category.category_name | capitalize }}</div>
                                    </div>
                                 </div>
                              </v-tab>
                           </v-tabs>

                        </v-col>
                        <v-col cols="12" md="8" class="px-1">
                           <div class="radio_group my-0 rounded-0 mb-2 pa-2" v-for="(service) in filteredServices"
                              :key="service.id" :class="{
                                 'blue1': selected_services.some(selectedService => selectedService.id == service.id),
                                 'white': !selected_services.some(selectedService => selectedService.id == service.id),
                              }">
                              <v-checkbox hideDetails :value="service"
                                 :color="selected_services.some(selectedService => selectedService.id == service.id) ? 'white' : 'blue1'"
                                 v-model="selected_services">
                                 <template v-slot:label>
                                    <v-row class="mx-0 caption d-flex justify-space-between"
                                       :class="{ 'white--text': selected_services.some(selectedService => selectedService.id == service.id) }">
                                       <v-col cols="auto" class="mx-0">{{ service.name | capitalize }}
                                          <v-tooltip v-if="service.description" bottom>
                                             <template v-slot:activator="{ on, attrs }">
                                                <v-icon :class="{ 'white--text': selected_services.some(selectedService => selectedService.id == service.id) }" size="19" v-bind="attrs" v-on="on">mdi-information</v-icon>
                                             </template>
                                             <span>{{service.description}}</span>
                                          </v-tooltip>
                                       </v-col>
                                       <v-col cols="auto" class="mx-0 text-end">{{ service.price_with_vat | float }}</v-col>
                                    </v-row>
                                 </template>
                              </v-checkbox>
           
                           </div>
                           <v-alert color="error" outlined dense v-if="filteredServices.length == 0" :value="true">
                              <small>{{ $t('ops, no services here') | capitalize }}</small>
                           </v-alert>
                        </v-col>
                     </v-row>



                  </v-col>

                  <v-col cols="12" sm="auto">
                     <v-divider vertical />
                  </v-col>

                  <v-col cols="12" md="6" lg="3" class="px-1">
                     <div class="subtitle-2 mb-5 gray8--text">
                        {{ $t('services details and totals') | capitalize }}
                     </div>
                     <v-sheet color="light" class="pa-3 rounded-lg">
                        <v-row justify="space-between">
                           <v-col cols="auto" class="caption gray3--text">
                              {{ barber_booking_date }}
                           </v-col>
                           <v-col cols="auto" class="caption gray3--text">
                              {{ barber_booking_date }}
                           </v-col>
                        </v-row>
                        <v-row justify="space-between" v-for="(service, index) in selected_services" :key="index">
                           <v-col cols="auto" class="caption black--text">
                              {{ service.name | capitalize }}
                           </v-col>
                           <v-col cols="auto" class="caption font-weight-bold black--text">
                              {{ service.price | float }}
                           </v-col>
                           <v-col cols="12" class="py-0 px-5"
                              v-if="(selected_services.length > index + 1)"><v-divider></v-divider></v-col>
                        </v-row>
                     </v-sheet>
                     <v-sheet color="light" class="pa-3 shadow mt-5 rounded-lg">
                        <v-row justify="space-between">
                           <v-col cols="auto" class="caption gray3--text">
                              {{ $t('total services') | capitalize }}
                           </v-col>
                           <v-col cols="auto" class="caption gray3--text">
                              {{ selected_services.length }}
                           </v-col>
                        </v-row>
                        <v-row justify="space-between">
                           <v-col cols="auto" class="caption gray3--text">
                              {{ $t('total after vat') | capitalize }}
                           </v-col>
                           <v-col cols="auto" class="caption gray3--text">
                              {{ totals.price | float }}
                           </v-col>
                        </v-row>
                        <v-row justify="space-between">
                           <v-col cols="auto" class="caption gray3--text">
                              {{ $t('total vat') | capitalize }}
                           </v-col>
                           <v-col cols="auto" class="caption gray3--text">
                              {{ totals.vat | float }}
                           </v-col>
                        </v-row>
                        <v-row justify="space-between">
                           <v-col cols="auto" class="caption font-weight-bold gray3--text">
                              {{ $t('total with vat') | capitalize }}
                           </v-col>
                           <v-col cols="auto" class="caption font-weight-bold gray3--text">
                              {{ totals.price_with_vat | float }}
                           </v-col>
                        </v-row>
                     </v-sheet>
                  </v-col>

               </v-row>

               <div class="my-16">
                  <AnimatedLoading :height="100" :isLoading="pageData.isLoading" />
               </div>
            </v-card>
         </v-form>
         <!-- End of Form -->

         <AddLockupsDialog entityName="customer" endPoint="customer" :enablePhone="true" :sendAsRequest="false"
            :dialog="addCustomerDialog" :closeMethod="() => { addCustomerDialog = false }" :saveMethod="saveCustomer" />

      </v-container>
   </section>
</template>



<script>
import AnimatedLoading from '@/components/AnimatedLoading.vue';
import GenericInput from '@/components/GenericInput.vue';
import AddLockupsDialog from '@/components/modals/AddLockupsDialog.vue';

export default {
   name: "BarberBookingControl",
   data: () => ({
      pageData: {
         screen_code: "10-004",
         url: null,
         controlRoute: "/barber/barber_booking/",
         entityName: null,
         main: null,
         category: null,
         isLoading: true,
         isEdit: false,
         editIsLoading: false,
      },
      valid: false,
      addCustomerDialog: false,
      form: {
         customer_id: null,
         customer_name: null,
         customer_mobile: null,
         notes: null,
         branch_name: null,
         branch_id: null,
         branch_address: null,
      },
      queryParam: '?',
      heCan: false,
      saveIsLoading: false,
      heCanIsLoading: false,
      barber: {
         image: null,
         name: null,
      },
      barber_booking_date: null,
      barber_id: null,
      start_time: null,
      customer_search: null,
      category_id: null,
      selected_customer: null,
      selected_services: [],
      // lockups
      services: [],
      categories: [],
      barber_customers: [],
      branches: [],
   }),
   components: { GenericInput, AnimatedLoading ,AddLockupsDialog},
   computed: {
      filteredServices() {
         if (this.services.length > 0 && this.category_id) {
            return this.services.filter(service => service.category_id === this.category_id)
         }
         else {
            return [];
         }
      },
      totals() {
         if (this.selected_services) {
            const price = this.selected_services.reduce((oldValue, newValue) => {
               return Number(+oldValue) + +newValue.price || 0;
            }, 0);
            const vat = this.selected_services.reduce((oldValue, newValue) => {
               return Number(+oldValue) + (+newValue.price * (newValue.tax_percent || 15 / 100)) || 0;
            }, 0);
            const price_with_vat = price + vat
            return {
               price: price,
               vat: vat,
               price_with_vat: price_with_vat,
            }
         }
         else {
            return {
               price: 0,
               vat: 0,
               price_with_vat: 0,
            }
         }
      }
   },
   watch: {
      selected_customer(val) {
         switch (typeof this.selected_customer) {
            case 'object':
               this.form.customer_id = val ? this.selected_customer.id : null;
               this.form.customer_name = val ? this.selected_customer.name : null;
               this.form.customer_mobile = val ? this.selected_customer.mobile : null;
               this.customer_search = null;
               break;

            case 'string':
               this.form.customer_id = 0;
               this.form.customer_name = null;
               this.customer_search = null;
               this.form.customer_mobile = this.selected_customer ?? null;
               break;

            default:
               this.form.customer_id = 0;
               this.form.customer_name = null;
               this.form.customer_mobile = null;
               this.customer_search = null;
               break;
         }
      },
      selected_services() {
         if (this.selected_services.length > 0)
            this.checkIFHeCan()
         else
            this.heCan = false
      },
      'form.customer_mobile'() {
         console.log('form.customer_mobile', this.form.customer_mobile);
         this.form.customer_name = this.form.customer_mobile ? this.$global.FilterArrayOfObjectElement(this.barber_customers, 'mobile', this.form.customer_mobile, 'name') : null
         this.form.customer_id = this.form.customer_mobile ? this.$global.FilterArrayOfObjectElement(this.barber_customers, 'mobile', this.form.customer_mobile, 'id') : null
      }
   },
   mounted() {
      this.pageMainData();
      this.queryParams();
      this.getData();
   },
   methods: {
      queryParams() {
         this.barber_id = +this.$store.state.activeScreen.queryParams.barber_id;
         this.start_time = +this.$store.state.activeScreen.queryParams.start_time;
         this.barber_booking_date = this.$store.state.activeScreen.queryParams.barber_booking_date;
         this.queryParam = this.$store.state.activeScreen.requestParam || '';
      },
      pageMainData() {
         this.pageData.main = this.$global.FilterPermissions(this.pageData.screen_code).main_title;
         this.pageData.category = this.$global.FilterPermissions(this.pageData.screen_code).cat_title;
         this.pageData.url = this.$global.FilterPermissions(this.pageData.screen_code).url;
         this.pageData.entityName = this.pageData.entityName = this.$route.params.id ? this.$i18n.t("edit") + " " + this.$i18n.t("booking") : this.$i18n.t("add") + " " + this.$i18n.t("booking");
      },
      getData() {
         if (this.barber_id && this.start_time && this.barber_booking_date) {
            if (this.$global.CheckAction(this.pageData.screen_code, 2)) {
               this.pageData.isLoading = true;
               this.categories = [];
               this.services = [];
               this.$api.GET_METHOD(`barber_booking/create${this.queryParam}`).then((response) => {
                  this.pageData.isLoading = false;
                  if (response.check) {
                     this.barber = response.data.barber || Object;
                     // this.services = response.data.services || [];
                     Object.keys(response.data.categorys).forEach(category_id => {
                        this.categories.push(response.data.categorys[category_id])
                        Object.keys(response.data.categorys[category_id].services).forEach(service_id => {
                           this.services.push(response.data.categorys[category_id].services[service_id])
                        })
                     })
                     // this.categories = response.data.categorys || [];
                     this.barber_customers = response.data.barber_customers || [];
                     this.category_id = this.categories.length > 0 ? this.categories[0].category_id : null;
                     this.form.branch_id = response.data.branchs.length > 0 ? response.data.branchs[0].id : null;
                     this.form.branch_name = response.data.branchs.length > 0 ? response.data.branchs[0].name : null;
                     this.form.branch_address = response.data.branchs.length > 0 ? response.data.branchs[0].address : null;
                     this.branches = response.data.branchs;
                  } else {
                     this.$router.push(this.pageData.url)
                  }
               })
            } else {
               this.$router.push(this.pageData.url)
               this.$store.state.snackbarTitle = 'make sure you have permission to add';
            this.$store.state.snackbarType = 'error';
            this.$store.state.showSnackbar = true;
            }
         } else {
            this.$router.push(this.pageData.url)
            this.$store.state.snackbarTitle = 'make sure to select start time , date and barber';
            this.$store.state.snackbarType = 'error';
            this.$store.state.showSnackbar = true;
         }


      },
      save() {
         const IDS = this.selected_services.map(service => service.id);
         const requestBody = {
            barber_booking_date: this.barber_booking_date,
            barber_id: +this.barber_id,
            services: IDS,
            start_time: +this.start_time,
            barber_customer_mobile: this.form.customer_mobile,
            barber_customer_name: this.form.customer_name,
            barber_customer_notes: this.form.notes,
            branch_id: this.form.branch_id
         }
         if (this.$refs.form.validate()) {
            this.saveIsLoading = true;
            this.$api.POST_METHOD(`barber_booking`, requestBody).then((response) => {
               this.saveIsLoading = false;
               if (response.check) {
                  this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               }
            })
         }
      },
      checkIFHeCan() {
         this.heCanIsLoading = true;
         const IDS = this.selected_services.map(service => service.id);
         const requestBody = {
            barber_booking_date: this.barber_booking_date,
            barber_id: +this.barber_id,
            services: IDS,
            start_time: +this.start_time,
         }
         this.$api.POST_METHOD(`check_booking_services_blocks`, requestBody).then((res) => {
            this.heCanIsLoading = false;
            this.heCan = res.check
         })
      },
      deleteMethod(status) {
         if (status == true) {
            this.pageData.isLoading = true
            this.$api.POST_METHOD(`barber/${this.form.id}`, { _method: 'delete' }).then(() => {
               this.$router.push(this.pageData.url + (this.$store.state.prevScreen.requestParam || ''))
               this.pageData.isLoading = false
            })
         }
      },
      saveCustomer(customer) {
         console.log('customer', customer);
         customer.mobile = customer.phone
         this.barber_customers.push(customer);
         this.form.customer_id = customer.id;
         this.form.customer_name = customer.name;
         this.form.customer_mobile = customer.phone;
         this.addCustomerDialog = false
      },

   },
};
</script>

<style lang="scss" scoped>
.activeItem {
   // border-right: 4px solid blue;
   background-color: #3163ff !important;
   color: #fff !important;

   .primary--text {
      color: #fff !important;
   }

   &::after {
      width: 5.22px;
      height: 10px;
      content: '';
      right: unset;
      left: 0;
      position: absolute;
      background-color: #3163ff !important;
      border-radius: 4px 0px 0px 3px;
   }
}

.radio_group {
   background-color: #f4f4f4 !important;
   border: 1px solid #e0e0e0 !important;
   margin-bottom: 1rem;
   padding: 1rem;
   border-radius: 8px;
}
</style>