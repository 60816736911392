<template>
    <v-dialog v-model="dialog" overlay-opacity="0.75" persistent max-width="452">
      <v-card relative class="backgroundW py-2">
        <v-card-title class="text-h6  text-center font-weight-bold">
          <h5 class="d-flex error--text ">
            <img src="@/assets/img/svg/bin.svg" class="mx-2" height="28" alt="erp" />
            <span>{{ $t('are you sure to delete') }}</span>
            <span :class="item ? 'mx-1': ''">{{ item }}</span>
            {{ $t('?') }}
          </h5>
        </v-card-title>

        <!-- <v-col cols="12" sm="12">
          <div
            class="body-2 px-5"
          ><p>{{ $t('Erase it permanently, it will be impossible to restore it again, we will wait 5 seconds When the final scan button is activated') }}</p></div>
        </v-col> -->

        <v-col cols="12" class="mt-3 px-8">
          <v-row justify="center">
            <v-col cols="auto">
              <v-btn @click="closeDialog" :height="40"  :width="170" depressed class="rounded-sm font-weight-bold  black--text" color="white">{{ $t('cancel') }}</v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn color="red10" depressed @click="returnData" :height="40" :width="170" class="rounded-sm font-weight-bold red1--text">
                <!-- <v-icon class="mx-1" small left>mdi-trash-can</v-icon> -->
                {{ $t('yes, delete it') }}
                <!-- <span v-if="counter !== 0">({{ $t(counter) }})</span> -->
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
</template>



<style scoped lang="scss">
</style>



<script>

export default {
  name: "DeleteConfirmation",
  props: {
    backValueMethod: { type: Function },
    dialog: { default: false },
    item: { default: "" },

  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  data() {
    return {
      counter: 5
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.backValueMethod(false);
      this.counter = 5
    },
    returnData() {
      this.dialog = false
      this.backValueMethod(true);
      // this.counter = 5
    },
    countDown() {
      setTimeout(() => {
        this.counter = this.counter - 1
        if (this.counter !== 0) {
          this.countDown()
        }
      }, 1000);
    },
  },
  mounted() {
    this.countDown();
  }
};
</script>
