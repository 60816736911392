<template>
  <v-dialog v-model="dialog" persistent max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="gray3" v-bind="attrs" v-on="on" depressed :min-width="120"
        :x-large="$vuetify.breakpoint.name == 'lg' || $vuetify.breakpoint.name == 'xl'"
        class="mx-1 white--text rounded-lg">
        <span class="d-md-block d-none" v-if="!barber.id">{{ $t('select barber') }}</span>
        <span class="caption font-weight-bold" v-if="barber.id">
          {{ barber.name }}</span>
      </v-btn>
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab color="grey lighten-1"
        text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6">{{ $t('change barber') }}</v-card-title>
      <v-divider></v-divider>
      <v-form class="pa-5">
        <v-col cols="12" sm="12">
          <v-btn-toggle v-model="toggle_barber" tile color="gray lighten-5" class="d-flex mb-2 justify-center" group>
            <v-row justify="center" align="center">
              <v-col cols="2" v-for="(barbeObj, index) in barbers" :key="index">
                <v-card outlined :color="barbeObj.id == barber.id ? 'blue12' : ''" class="pa-3"  @click="returnData(barbeObj)">
                  <img class="d-block ma-auto rounded-circle" width="70" height="70"
                    :src="barbeObj.image ? $store.state.endpointURL + barbeObj.image : require('@/assets/img/pos_svg/avatar.svg')" :alt="barbeObj.name">
                  <div class="subtitle-2 mt-3 text-center">
                    {{ barbeObj.name }}
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="barbers.length == 0">
                <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('No data available') }}</v-alert>
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-col>
      </v-form>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>
export default {
  name: "BarberModal",
  props: {
    backValueMethod: { type: Function },
    barbers: { default: [] },
    barber: { default: Object },
  },
  computed: {},
  watch: {

  },
  mounted() {

  },
  data() {
    return {
      dialog: false,
      toggle_barber: 1,
    };
  },
  methods: {
    returnData(barber) {
      this.backValueMethod(barber);
      this.dialog = false
    },
  },
};
</script>
