<template>
  <section id="bgAuth" class="light">
    <v-container fluid>
      <v-col cols="12" md="10" lg="8" xl="6" class="ma-auto">
        <div class="justify-center d-flex align-end">
          <v-col cols="12" class="pa-0">
            <v-card elevation-0  class="shadow d-flex align-center mt-10 rounded-0" min-height="570">
              <v-col cols="9" class="px-5">
                <v-btn v-on:click="changeLanguage" small fab class="bg-white langBtn d-block ma-auto" elevation="0">
                  <img src="@/assets/img/svg/USA.svg" height="30" v-if="$vuetify.rtl == true" alt="Qawaem-POS" />
                  <img src="@/assets/img/svg/KSA.svg" height="30" v-if="$vuetify.rtl == false" alt="Qawaem-POS" />
                </v-btn>
                <v-col cols="12" class="mb-3">
                  <img src="@/assets/logo.png" height="80"  class="mb-3" alt="Qawaem">
                  <div class="text-h5 font-weight-bold">
                    {{ $t('welcome') | capitalize }}, <span class="secondary--text">{{ $store.state.userData.full_name | capitalize }}</span>
                  </div>
                  <div class="subtitle-1 gray8--text font-weight-bold">
                    {{ $t('thank you for your dedication to work') | capitalize }}
                  </div>
                </v-col>
                <v-form ref="form" class="px-5" v-model="valid" lazy-validation>

                  <v-col cols="12" class="mb-3">
                    <div class="subtitle-1 font-weight-bold mb-3">{{$t('select branch') | capitalize}}</div>
                    <v-btn-toggle v-model="form.branch" class="row">
                      <v-btn
                        :class="form.branch == branch.id ? 'white--text ma-2 rounded-sm': 'black--text ma-2 rounded-sm'"
                        :color="form.branch == branch.id ? 'primary': 'light'" style="border: 0 !important;"
                        v-for="(branch, index) in $store.state.branches" :key="index" :value="branch.id">
                        {{branch.name}}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>


                  <!-- amount number -->
                  <GenericInput type="float" name="cash" backgroundColor="transparent" icon="mdi-currency-usd" :keydownEnter="startShift" :solo="true"
                    :value="form.cash" @input="form.cash = $event" label="start shift cash balance" :required="false"
                    :isLoading="false" :cols="[12, 12, 12]"></GenericInput>

                  <v-col cols="12" class="d-flex justify-space-between align-center">
                    <v-btn depressed :height="40" text @click="$router.push('/login')" color="error">
                      <v-icon>mdi-arrow-right-thin</v-icon>
                      {{ $t('logout') }}
                    </v-btn>
                    <v-btn depressed :height="40" width="150" :disabled="!(valid && form.branch)" :loading="loading"
                      @click="startShift" color="secondary">{{ $t('start shift') }}
                    </v-btn>
                  </v-col>


                </v-form>
              </v-col>

            </v-card>
          </v-col>
          <v-col cols="12" md="auto" class="d-none d-md-block pa-0" id="imgAuth">
            <v-card  elevation-0 class=" d-flex align-center justify-center rounded-0" width="400" height="570">
              <img src="@/assets/img/pos_svg/cashierBG.png" alt="Qawaem">
              <div class="pa-5">
                <div class="white--text text-h5 my-5">{{ $t('welcome in qawaem') | capitalize }}</div>
                <div class="white--text text-h6 my-5">{{ $t('join our community of more than 10,000 subscribers') |
                capitalize
                }}</div>
              </div>

            </v-card>
          </v-col>
        </div>
      </v-col>
    </v-container>
  </section>
</template>





<script>
import GenericInput from '@/components/GenericInput.vue';
export default {
  name: "StartShiftPage",
  computed: {
  },
  watch: {

  },
  mounted() {
    this.pageMainData();
  },
  components: {
    GenericInput

  },
  data: () => ({
    valid: false,
    loading: false,
    branchesIsLoading: false,
    form: {
      cash: 0,
      branch: null,
    }
  }),
  methods: {
    changeLanguage() {
      this.$store.dispatch("Settings/changeLanguage");
    },
    pageMainData() {
      if (this.$store.state.isAuth == true && this.$store.state.shiftOpen == false) {
        this.form.branch = this.$store.state.branches[0].id || null;
      }
      else {
        if (this.$store.state.isAuth == true && this.$store.state.shiftOpen == true) {
          this.$router.push('/');
        } else {
          this.$router.push('/login');
        }
      }
    },
    startShift() {
      this.loading = true;
      this.$refs.form.validate()
      if (this.valid == true) {
        this.$api.POST_METHOD('start_shift', this.form).then((response) => {
          this.loading = false;
          if (response.check == true) {
            this.$router.push('/');
          }
        })
      } else {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped lang="scss">
  #bgAuth {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 200px;
  
    .langBtn {
      position: absolute;
      top: 30px;
      right: unset !important;
      left: 50px !important;
    }
  
    #imgAuth {
      position: relative;
      top: -55px;
      left: -200px;
      overflow: hidden;
  
      img {
        position: absolute;
        top: 0
      }
      -webkit-box-shadow: 0px 0px 99px -3px rgb(136 136 136);
      -moz-box-shadow: 0px 0px 99px -3px rgba(136,136,136,1);
      box-shadow: 0px 0px 99px -3px rgb(136 136 136) !important;
      .text-h6,
      .text-h5 {
        position: relative;
        z-index: 10;
        text-align: center;
      }
    }
  }
  
  .v-application--is-rtl {
    #bgAuth {
      padding-left: 0;
      padding-right: 200px;
  
      .langBtn {
        left: unset !important;
        right: 50px !important;
      }
    }
  
    #imgAuth {
      left: unset;
      right: -200px;
    }
  }
  </style>