<template>
  <v-dialog v-model="dialog" persistent :max-width="type ? 400 : 600" :key="dialogKey">
    <template v-slot:activator="{ on, attrs }">

      <v-btn depressed block v-bind="attrs" v-on="on" class="white--text" color="gray4">
        <v-icon left size="25">mdi-cash-plus</v-icon>
        {{ $t('paid') }}
      </v-btn>
      <!-- <v-btn rounded-md class="bg-white" v-bind="attrs" v-on="on" depressed>
          <img class="mx-1" src="../../assets/img/svg/pay1.svg" alt="TheConcept-POS" />
          <span class="d-md-block d-none">{{ type ? $t(type) : $t("choose payment method") }}</span>
        </v-btn> -->
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" v-if="!type" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>

      <div class="d-flex px-2 align-center justify-space-around" v-if="type">
        <v-col cols="auto" class="pa-0 pt-2">
          <img src="@/assets/img/pos_svg/back arrow.svg" @click="type = null"
            class="light cursor_pointer rounded-pill pa-2" height="40" alt="qawaem" />
        </v-col>
        <v-col cols="auto">
          <v-row align="center">

            <v-col cols="auto" class="pa-0">
              <div class="d-block pr-3 pt-3 grey lighten-1 rounded-pill"></div>
            </v-col>
            <v-col cols="auto">
              <h6 class="my-0 py-0">
                {{ $t("the amount required") }} :
                <strong>{{ getInvoiceStore.total_price_after_discount_with_vat | float }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="auto" class="pa-0">
          <v-row>
            <v-col cols="auto">
              <div class="d-block pr-3 pt-3 grey lighten-1 rounded-pill"></div>
            </v-col>
            <v-col cols="auto">
              <h6 class="my-0 py-0" :style="remainingPrice > 0 ? 'color: #d50000' : 'color: #0bac30'">
                <span v-if="remainingPrice > 0">
                  {{ $t("remaining") }} :
                </span>
                <span v-if="remainingPrice < 0">
                  {{ $t("more") }} :
                </span>
                <span v-if="remainingPrice == 0">
                  {{ $t("ok") }} :
                </span>
                <strong>{{ remainingPrice | float }}</strong>
              </h6>
            </v-col>
          </v-row>
        </v-col>
      </div>

      <v-col cols="12" class="pa-10" v-if="!type">
        <v-row justify="space-around">
          <v-col cols="4">
            <v-card outlined height="150" class="ma-auto rounded-xl d-flex justify-center align-center mt-2"
              @click="type = 'payCash'; checkPaymentType('payCash')" width="150">
              <div>
                <img src="@/assets/img/pos_svg/cash.svg" height="50" alt="qawaem" class="d-block ma-auto">
                <div class="subtitle-1 text-center mt-2">{{ $t('cash') }}</div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined height="150" class="ma-auto rounded-xl d-flex justify-center align-center mt-2"
              @click="type = 'payBank'; checkPaymentType('payBank')" width="150">
              <div>
                <img src="@/assets/img/pos_svg/credit.svg" height="50" alt="qawaem" class="d-block ma-auto">
                <div class="subtitle-1 text-center mt-2">{{ $t('network') }}</div>
              </div>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card outlined height="150" class="ma-auto rounded-xl d-flex justify-center align-center mt-2"
              @click="type = 'payCashBank'; checkPaymentType('payCashBank')" width="150">
              <div>
                <img src="@/assets/img/pos_svg/cash_credit.svg" height="50" alt="qawaem" class="d-block ma-auto">
                <div class="subtitle-1 text-center mt-2">{{ $t('cash + network') }}</div>
              </div>
            </v-card>
          </v-col>

        </v-row>

      </v-col>

      <v-form class="px-5 pb-5" onsubmit="return false;" v-if="type">

        <v-col cols="12" sm="12">
          <v-col cols="12" class="py-0 mb-2 " v-if="activeStep == 'bankNumberPad'">
            <v-row>
              <v-col cols="6" class="py-1">
                <v-btn depressed @click="setCartType(3)" text block class="mx-2"
                  :class="cart_type == 3 ? 'gray11' : ''" color="primary">
                  <v-icon left color="success" v-if="cart_type == 3">mdi-check</v-icon>
                  <v-icon left color="success" v-else></v-icon>
                  <img src="@/assets/img/svg/mada.svg" height="20" alt="visa">
                </v-btn>
              </v-col>
              <v-col cols="6" class="py-1">
                <v-btn depressed  @click="setCartType(1)" text block class="mx-2"
                  :class="cart_type == 1 ? 'gray11' : ''" color="primary">
                  <v-icon left color="success" v-if="cart_type == 1">mdi-check</v-icon>
                  <v-icon left color="success" v-else></v-icon>
                  <img src="@/assets/img/svg/visa.svg" height="20" alt="visa">
                </v-btn>
              </v-col>
              <v-col cols="6" class="py-1">
                <v-btn depressed  @click="setCartType(2)" text block class="mx-2"
                  :class="cart_type == 2 ? 'gray11' : ''" color="primary">
                  <v-icon left color="success" v-if="cart_type == 2">mdi-check</v-icon>
                  <v-icon left color="success" v-else></v-icon>
                  <img src="@/assets/img/svg/mastercard.svg" height="30" alt="visa">
                </v-btn>
              </v-col>
              <v-col cols="6" class="py-1">
                <v-btn depressed  @click="setCartType(4)" text block class="mx-2"
                  :class="cart_type == 4 ? 'gray11' : ''" color="primary">
                  <v-icon left color="success" v-if="cart_type == 4">mdi-check</v-icon>
                  <v-icon left color="success" v-else></v-icon>
                  <img src="@/assets/img/svg/khaleg_network.png" height="30" alt="visa">
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <h5 class="mb-5" v-if="activeStep == 'cashNumberPad'">{{ $t("pay cash") }}</h5>
          <h5 class="mb-5" v-if="activeStep == 'bankNumberPad'">{{ $t("pay network") }}</h5>
          <NumberPad v-if="bankNumberPad == true && activeStep == 'bankNumberPad'" :backValueMethod="setBankPrice"
            :value="bankPrice"></NumberPad>
          <NumberPad v-if="cashNumberPad == true && activeStep == 'cashNumberPad'" :backValueMethod="setPrice"
            :value="price"></NumberPad>
        </v-col>

        <v-row justify="space-between" class="mb-1">


          <v-col cols="5" class="py-0">
            <v-btn color="error" :height="40" text plain @click="dialog = false">{{ $t("cancel") }}</v-btn>
          </v-col>

          <v-col cols="auto" class="px-5 py-0" v-if="stepTwo && activeStep != stepTwo">
            <v-btn color="success" :height="40" min-width="120" @click="nextStep" class="shadow">{{ $t("next") }}
            </v-btn>
          </v-col>

          <v-col :cols="!stepTwo ? 'auto' : 'auto'" class="px-5 py-0" v-if="!stepTwo || (activeStep == stepTwo)">
            <v-btn color="success" :height="40" min-width="120" :disabled="!valid" @click="submitPayment" depressed>
              {{ $t("pay") }}
            </v-btn>
          </v-col>

        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss"></style>



<script>
import NumberPad from "../NumberPad.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("InvoiceStore");
export default {
  name: "PaymentsModal",
  props: {
    backValueMethod: { type: Function },
    type: { default: "" },
    machineID: { default: "" },
    cart_type: { default: 3 },
    setCartType: { type: Function },
  },
  watch: {
    type() {
      this.price = '';
      this.bankPrice = '';
      this.transferPrice = '';
      if (this.type == 'payBank') {
        this.setBankPrice(String(this.remainingPrice.toFixed(2)))
      }
    },
    dialog() {
      this.activeStep = '';
      this.type = ''
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F9':
          this.dialog = !this.dialog
          break;
        case 'Escape':
          this.dialog = false
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.altKey == true && e.code == "KeyP") {
        this.dialog = true;
      }
      if (e.code == 'Escape') {
        this.dialog = false;
      }
    });
    window.addEventListener("keydown", e => {
      console.log(this.dialog == true, this.type, this.price);
      if (e.key == 'Enter' && this.dialog == true && this.type && this.price || this.bankPrice) {
        if (this.type == 'payCashBank' && this.price && this.bankPrice == '') {
          this.activeStep = 'bankNumberPad'
        }
        if (this.valid) {
          this.submitPayment();
          this.dialogKey = this.dialogKey + 1
        }

      }
    });
  },
  computed: {

    ...mapGetters(["getInvoiceStore"]),
    valid() {
      switch (this.type) {
        case "payCash":
          if (this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) <= Number(+this.price)) {
            return true;
          } else {
            return false
          }
        case "payCashBank":
          if (this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) == (Number(+this.price) + Number(+this.bankPrice))) {
            return +this.price && +this.bankPrice;
          } else {
            return false
          }
        case "payBank":
          if (this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) == Number(+this.bankPrice)) {
            return true;
          } else {
            return false
          }
        case "transferPayment":
          if (this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) == Number(+this.transferPrice)) {
            return true;
          } else {
            return false
          }
        case "transferAndCashPayment":
          if (this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) == (Number(+this.price) + Number(+this.transferPrice))) {
            return +this.price && +this.transferPrice;
          } else {
            return false
          }
        case "paymentTransferAndBank":
          if (this.getInvoiceStore.total_price_after_discount_with_vat.toFixed(2) == (Number(+this.bankPrice) + Number(+this.transferPrice))) {
            return +this.bankPrice && +this.transferPrice;
          } else {
            return false
          }

        default:
          return false;
      }
    },
    remainingPrice() {
      return +this.getInvoiceStore.total_price_after_discount_with_vat - (+this.price || 0 + +this.bankPrice || 0)
    }
  },
  components: {
    NumberPad,
  },
  methods: {
    checkPaymentType() {
      switch (this.type) {
        case "payCash":
          this.activeStep = "cashNumberPad";
          this.stepOne = "cashNumberPad";
          this.stepTwo = null
          this.cashNumberPad = true
          break;
        case "payCashBank":
          this.activeStep = "cashNumberPad";
          this.stepOne = "cashNumberPad";
          this.stepTwo = "bankNumberPad";
          this.cashNumberPad = true
          this.bankNumberPad = true;
          break;
        case "payBank":
          this.activeStep = "bankNumberPad";
          this.stepOne = "bankNumberPad";
          this.stepTwo = null;
          this.bankNumberPad = true;
          break;
        case "transferPayment":
          this.activeStep = "transferNumberPad";
          this.stepOne = "transferNumberPad";
          this.stepTwo = null;
          this.transferNumberPad = true
          break;
        case "transferAndCashPayment":
          this.activeStep = "cashNumberPad";
          this.stepOne = "cashNumberPad";
          this.stepTwo = "transferNumberPad";
          this.cashNumberPad = true
          this.transferNumberPad = true;
          break;
        case "paymentTransferAndBank":
          this.activeStep = "bankNumberPad";
          this.stepOne = "bankNumberPad";
          this.stepTwo = "transferNumberPad";
          this.bankNumberPad = true
          this.transferNumberPad = true;
          break;
        default:
          return false;
      }

      // console.log(type, this.stepOne, this.stepTwo);


    },
    nextStep() {
      this.activeStep = this.stepTwo
      if (this.stepTwo == 'bankNumberPad') {
        this.setBankPrice(this.remainingPrice.toFixed(2))
      }
    },
    prevStep() {
      this.activeStep = this.stepOne
    },
    submitPayment() {
      var payment = [];

      switch (this.type) {
        case "payCash":
          payment = [
            {
              "machine_id": 0,
              "amount": +this.price,
              "payment_type": 1,
              "payment_text": this.$i18n.t('cash'),
            }
          ]
          this.backValueMethod(payment, "payCash");
          this.dialog = false;
          break;
        case "payCashBank":
          payment = [
            {
              "machine_id": 0,
              "amount": +this.price,
              "payment_type": 1,
              "payment_text": this.$i18n.t('cash'),
              cart_type: this.cart_type,
            },
            {
              "machine_id": this.machineID,
              "amount": +this.bankPrice,
              "payment_type": 2,
              "payment_text": this.$i18n.t('network'),
              cart_type: this.cart_type,
            }
          ]
          this.backValueMethod(payment, "payCashBank");
          this.dialog = false;
          break;
        case "payBank":
          payment = [
            {
              "machine_id": this.machineID,
              "amount": +this.bankPrice,
              "payment_type": 2,
              "payment_text": this.$i18n.t('network'),
              cart_type: this.cart_type,
            }
          ]
          this.backValueMethod(payment, "payBank");
          this.dialog = false;
          break;
        case "transferPayment":
          payment = [
            {
              "machine_id": 0,
              "amount": +this.transferPrice,
              "payment_type": 3,
              "payment_text": this.$i18n.t('transfer'),
            }
          ]
          this.backValueMethod(payment, "transferPayment");
          this.dialog = false;
          break;
        case "transferAndCashPayment":
          payment = [
            {
              "machine_id": 0,
              "amount": +this.price,
              "payment_type": 1,
              "payment_text": this.$i18n.t('cash'),
            },
            {
              "machine_id": 0,
              "amount": +this.transferPrice,
              "payment_type": 3,
              "payment_text": this.$i18n.t('transfer'),
            }
          ]
          this.backValueMethod(payment, "transferAndCashPayment");
          this.dialog = false;
          break;
        case "paymentTransferAndBank":
          payment = [
            {
              "machine_id": 0,
              "amount": +this.transferPrice,
              "payment_type": 3,
              "payment_text": this.$i18n.t('transfer'),
              cart_type: this.cart_type,
            },
            {
              "machine_id": this.machineID,
              "amount": +this.bankPrice,
              "payment_type": 2,
              "payment_text": this.$i18n.t('network'),
              cart_type: this.cart_type,
            }
          ]
          this.backValueMethod(payment, "paymentTransferAndBank");
          this.dialog = false;
          break;
        default:
          break;
      }
    },
    setPrice(returnedPrice) {
      this.price = returnedPrice;
    },
    setBankPrice(returnedPrice) {
      console.log(returnedPrice);
      this.bankPrice = returnedPrice;
    },
    setTransferPrice(returnedPrice) {
      this.transferPrice = returnedPrice;
    },
  },
  data() {
    return {
      dialog: false,
      dialogKey: 1,
      price: '',
      bankPrice: '',
      transferPrice: '',
      cashNumberPad: false,
      bankNumberPad: false,
      transferNumberPad: false,
      stepOne: null,
      stepTwo: null,
      stepOnePrice: 0,
      stepTwoPrice: 0,
      activeStep: '',
    };
  },
};
</script>
