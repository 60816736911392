import store from "../store";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage  } from "firebase/messaging";
import * as ff from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDRK6DEMzdRbEoQ6JefZP06k2-GTTfzg8o",
  authDomain: "theconcepttv-c67ae.firebaseapp.com",
  projectId: "theconcepttv-c67ae",
  storageBucket: "theconcepttv-c67ae.appspot.com",
  messagingSenderId: "825441233425",
  appId: "1:825441233425:web:c923dabaaa25507e3d5fb0",
  measurementId: "G-E2KJHHXR9G"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
console.log('d', ff);
onMessage(messaging, (payload) => {
  if (payload) {
    store.state.firebaseNotifications = payload
  } else {
    console.log('false');
  }

});

export default {
  install(Vue) {
    Vue.prototype.$getToken = () => {
      return getToken(messaging, { vapidKey: "BBimYrv98t4euSVCvHFiH4LsXuSCrFdLILZZya36SwwSP36MM6QU-bUFCUkPElvHd9yqvy9_7OUlDWz1jC3vi10" }).then((currentToken) => {
        if (currentToken) {
          return currentToken;
        } else {
          console.log('No registration token available. Request permission to generate one.');
          return null;
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        return null;
      });
    };
  }
};
