<template>
  <section>
    <v-container fluid>

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="6" lg="7" class="py-2">
          <v-row align="center">
            <v-col cols="auto" class="d-none d-lg-block">
              <div class="font-weight-bold"> {{ $t('pending requests') }}</div>
            </v-col>
            <GenericInput type="date" paddingX="px-1" backgroundColor="white" :clearable="false"
              dense :solo="false" prefix="from date" :value="from_date" :maxDate="to_date || today"
              @input="from_date = $event" label="" :required="false" :hide-details="true"
              :isLoading="pageData.isLoading" :cols="[6, 6, 4]">
            </GenericInput>
            <GenericInput type="date" paddingX="px-1" backgroundColor="white" :clearable="false"
              dense :solo="false" prefix="to date" :value="to_date" :minDate="from_date" :maxDate="today"
              @input="to_date = $event" label="" :required="false" :hide-details="true"
              :isLoading="pageData.isLoading" :cols="[6, 6, 4]">
            </GenericInput>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" lg="5" class="py-2">
          <v-row justify="end">
            <v-col cols="12" md="5">
              <v-text-field v-model="pageData.search" 
                :loading="pageData.isLoading" outlined @keydown.enter="(e) => getSearch(e.target.value)"
                @click:clear="(e) => getSearch(e.target.value)" @click:append="getSearch(pageData.search)"
                append-icon="mdi-magnify" :placeholder="
                  pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('search')
                " single-line clearable hide-details solo dense :height="37"></v-text-field>
            </v-col>
            <v-col cols="12" md="auto">
              <v-btn block outlined :loading="pageData.isLoading" @click="getData()" :height="37" :width="100"
                color="blue4" class="rounded-lg">
                {{ $t("search") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="space-between">
        <!-- All Pending Requests Master -->
        <v-col cols="12" sm="8" lg="7" class="py-2">
          <v-card class="shadow py-2 px-3 rounded-lg overflow-hidden">
            <v-row align="center" justify="space-between">
              <v-col cols="12" sm="auto" class="pb-2">
                <div class="font-weight-bold caption"> {{ $t('pending waiting requests') }}</div>
              </v-col>
              <v-col cols="12" sm="auto" class="pb-2">
                <div class="font-weight-bold caption success--text" v-if="pageData.rows.length"> {{ $t('requests') }}: {{
                pageData.rows.length || 0 }}</div>
              </v-col>
              <v-col cols="12" class="pa-0 pt-2">
                <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 200" cardClasses="my-0"
                  :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
                  :rowClicked="rowClicked" :option="pageData.options" :pageData="pageData"> </DynamicTable>
              </v-col>

            </v-row>
          </v-card>
          <v-col cols="12" sm="12" v-if="!pageData.isLoading && pageData.rows.length > 0">
            <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
              :isLoading="pageData.isLoading"></Pagination>
          </v-col>
        </v-col>

        <!-- Selected Master Details -->
        <v-col cols="12" sm="4" lg="5" class="py-2" v-if="selectedRow">
          <v-card class="shadow py-2 px-3 rounded-lg overflow-hidden">
            <v-row align="center" justify="space-between">
              <v-col cols="12" sm="6" class="pb-0">
                <div class="font-weight-bold"> {{ $t('request details') }} <small
                    class="success--text font-weight-medium">#{{selectedRow.m_id}}</small></div>
              </v-col>
              <v-col cols="12" sm="6" class="pb-0 text-end">
                <div class="success--text"> {{ selectedRow.sales_invoice_date }}
                </div>
              </v-col>

              <v-col cols="auto" class="pb-2 pt-0">
                <div class="body-2">
                  {{ selectedRow.customer_name }}
                </div>
              </v-col>
              <v-col cols="auto" class="pb-2 pt-0 text-center">
                <div class=""> {{ selectedRow.customer_phone }}
                </div>
              </v-col>
              <v-col cols="auto" class="pb-2 pt-0">
                <v-btn @click="applyRequest" icon> <img src="@/assets/img/pos_svg/edit-files.svg" height="18"
                    alt="qawaem" /></v-btn>
              </v-col>

              <v-col cols="12" class="pa-0 pt-2">
                <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 200" :hideHeader="true"
                  cardClasses="my-0" :isLoading="isDetailsLoading" :data="requestDetails" :header="requestTableHeader"
                  :option="pageData.options" :pageData="pageData"> </DynamicTable>
              </v-col>
              <v-col cols="12" v-if="requestDetails.length > 0">
                <v-btn depressed class="d-block mi-start-auto shadow rounded-lg" @click="applyRequest" :height="40"
                  :min-width="120" color="secondary">
                  {{ $t("apply request") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>


      </v-row>


    </v-container>
  </section>
</template>

<script>
import GenericInput from '@/components/GenericInput.vue';
import DynamicTable from '@/components/DynamicTable.vue';
import Pagination from '@/components/Pagination.vue';


export default {
  name: "PendingRequestsEntity",
  computed: {

  },
  components: {
    GenericInput,
    DynamicTable,
    Pagination
},

  data: () => ({
    pageData: {
      screen_code: "06-008",
      url: '/pending-requests',
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    requestTableHeader: [],
    requestDetails: [],
    from_date: null,
    to_date: null,
    today: null,
    selectedRow: null,
    isDetailsLoading: false,
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F5':
          this.getData();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
      this.queryParams();
      this.today = this.$global.GetCurrentDate();
      this.from_date = this.$global.GetFirstDayOfMonth();
      this.to_date = this.$global.GetCurrentDate();
      this.pageMainData();
      this.getData();
    } else {
      this.$router.push("/");
      this.$global.ShowAlert('info', this.$i18n.t('no permission'), false, this.$i18n.t('check permission with admin'))
    }
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 10;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.tableHeader = [
        { text: "request", key: "m_id", type: 'text', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "mobile", key: "customer_phone", type: 'text', classes: "" },
        { text: "date", key: "sales_order_date", type: 'text', classes: "" },
        { text: "items count", key: "items_count", type: 'text', classes: "" },
        { text: "value", key: "total_price_after_discount", type: 'float', classes: "" },
        { text: "cashier", key: "user_full_name", type: 'text', classes: "" },
      ];
      this.requestTableHeader = [
        // { text: "image", key: "item_image", type: 'img', classes: "" },
        { text: "item", key: "item_name", type: 'text', classes: "" },
        { text: "items count", key: "quantity", type: 'pieces', classes: "" },
        { text: "value", key: "total_with_vat", type: 'float', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}&from_date=${this.from_date}&to_date=${this.to_date}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`get_sales_orders${this.pageData.queryParam}&from_date=${this.from_date}&to_date=${this.to_date}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.entityName =
              this.pageData.isTrashed == 0
                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                : this.$store.state.activeScreen.sub_title;
            this.pageData.rows = response.data.items.data;
            this.pageData.page = response.data.items.current_page;
            this.pageData.pagination = {
              page: response.data.items.current_page,
              totalPages: response.data.items.last_page,
              per_page: response.data.items.per_page,
              totalRows: response.data.items.total,
            };

          }
        });
    },
    getRequestDetails() {
      this.isDetailsLoading = true;
      this.requestDetails = []
      this.$api
        .GET_METHOD(`get_sales_order/${this.selectedRow.m_id}`)
        .then((response) => {
          this.isDetailsLoading = false;
          if (response.check) {
            this.requestDetails = response.data.details;
          }
        });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    rowClicked(row) {
      this.requestDetails = [];
      this.selectedRow = row;
      this.getRequestDetails();
    },
    applyRequest() {
      this.$router.push({
        name: "SalesPendingRequestShow",
        params: { pending_request: this.selectedRow, id: this.selectedRow.m_id },
      });
    }
  },

};
</script>
