<template>
   <div>
      <v-row>
         <v-col cols="auto" class="d-flex align-center pb-0 mt-2">
            <!-- <v-btn small @click.stop="toggleSidebar" :class="$vuetify.rtl == true ? '' : 'rotate-180'" fab elevation="0"
               height="40" width="40">
               <img src="@/assets/img/pos_svg/menu.svg" class="mx-2" height="20" alt="Qawaem-POS" />
            </v-btn> -->
            <div class="subtitle-2 mx-2">
               <div class="caption font-weight-bold primary--text">
                  {{ $t('welcome') | capitalize }}, <span class="secondary--text">{{ $store.state.userData.full_name | capitalize }}</span>
               </div>
            </div>

         </v-col>
         <v-col cols="auto" class="pa-5 pt-2 d-flex align-center pb-0">
            <CompanyDetailMenu />
         </v-col>

      </v-row>
      <v-list dense class="mt-3 px-0">
         <!-- List Level 1 link -->

         <!-- Static -->
         <div class="d-block text-center" v-for="(list, index) in staticList" :key="index">
            <v-list-item active-class="activeItem" v-if="$global.CheckAction(list.screen_code, 1) || list.screen_code ==='00-000'" link :to="list.router" exact-active-class="activeLink" color="primary" class="my-1">
               <v-list-item-icon class="mx-2">
                  <img :src="getImgUrl(list.img)" class="mx-n2" height="20" width="20" :alt="`Qawaem - ${list.title}`" />
               </v-list-item-icon>
               <v-list-item-title class="text-start">{{ $t(list.title) | capitalize }}</v-list-item-title>
            </v-list-item>
         </div>

         <!-- Dynamic -->

         <span v-for="(list, i) in menuList" :key="i" class="d-block text-center">
            <v-list-item active-class="activeItem" v-if="Object.keys(list.cats).length == 0" link :to="list.url"
               color="primary">
               <v-list-item-icon>
                  <img v-if="list.image" :src="$api.serverUrl + list.image" :alt="list.title"
                     :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'" />
                  <v-icon v-if="!list.image" :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'">mdi-file-image-outline
                  </v-icon>
               </v-list-item-icon>
               <v-list-item-title class="text-start">{{ list.title | capitalize }}</v-list-item-title>
            </v-list-item>
            <!-- List Level 1 group -->
            <v-list-group :ripple="false" color="primary" class="pa-1" active-class="activeLink"
               v-if="Object.keys(list.cats).length >= 1" :value="$store.state.activeScreen.main_id == list.id">
               <template v-slot:activator>
                  <v-list-item-icon>

                     <img v-if="list.image" :src="$api.serverUrl + list.image" :alt="list.title"
                        :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'" />
                     <v-icon v-if="!list.image" :class="$store.state.sidebarIsOpen ? '' : 'mx-n2'">
                        mdi-file-image-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ list.title | capitalize }}</v-list-item-title>
               </template>

               <!-- List Level 2 list-->

               <span v-for="(cats, y) in list.cats" :key="y" class="d-block my-0 text-center">
                  <v-list-item active-class="activeLink" v-if="cats.subs.length == 0" link :to="cats.url">
                     <v-list-item-title>{{ cats.title | capitalize }}</v-list-item-title>
                  </v-list-item>
                  <!-- List Level 2 group-->
                  <div>
                     <v-list-group color="primary" active-class="activeLink" v-if="cats.subs.length >= 1" sub-group
                        :value="$store.state.activeScreen.cat_id == cats.id">
                        <template v-slot:activator>
                           <v-list-item-title>{{ cats.title | capitalize }}</v-list-item-title>
                           <v-icon size="22">mdi-chevron-down</v-icon>
                        </template>

                        <!-- List Level 3 link -->
                        <span v-for="(subs, z) in cats.subs" :key="z" class="d-block my-1">
                           <v-list-item :class="$store.state.activeScreenCode == subs.screen_code ? 'activeLink' : ''"
                              v-if="subs" link :to="subs.url">
                              <v-list-item-title>{{ subs.sub_title | capitalize }}</v-list-item-title>
                           </v-list-item>
                        </span>

                     </v-list-group>
                  </div>
               </span>
            </v-list-group>
         </span>


         <!-- <div class="d-block text-center">
            <v-list-item  class="my-1 cursor_pointer" @click="$api.LOGOUT(true)">
               <v-list-item-icon class="mx-2">
                  <img src="@/assets/img/pos_svg/logout.svg" class="mx-n2" height="20" width="20" :alt="`Qawaem - logout`" />
               </v-list-item-icon>
               <v-list-item-title class="text-start">{{ $t('logout') | capitalize }}</v-list-item-title>
            </v-list-item>
         </div> -->
      </v-list>
   </div>
</template>

<script>
import CompanyDetailMenu from './CompanyDetailMenu.vue';

export default {
    name: "Sidebar",
    mounted() {
        this.staticList = [
            {
                img: "home.svg",
                title: "home",
                router: "/",
                screen_code: "00-000",
            },
            {
                img: "sales.svg",
                title: "sales",
                router: "/sales",
                screen_code: "06-008",
            },
            {
                img: "pending_orders_menu.svg",
                title: "pending requests",
                router: "/pending-requests",
                screen_code: "06-008",
            },
            {
                img: "orders-menu.svg",
                title: "requests list",
                router: "/requests-list",
                screen_code: "06-008",
            },
            {
                img: "scissors.svg",
                title: "barber booking",
                router: "/barber-booking",
                screen_code: "10-004",
            },
            {
                img: "notice-debtor.svg",
                title: this.$global.FilterPermissions("06-010") ? this.$global.FilterPermissions("06-010").sub_title : null,
                router: "/notice-debtor",
                screen_code: "06-010",
            },
            {
                img: "notice-creditor.svg",
                title: this.$global.FilterPermissions("06-011") ? this.$global.FilterPermissions("06-011").sub_title : null,
                router: "/notice-creditor",
                screen_code: "06-011",
            },
            {
                img: "reports.svg",
                title: "reports",
                router: "/reports",
                screen_code: "06-008",
            },
            {
                img: "full-report.svg",
                title: this.$global.FilterPermissions("06-012") ? this.$global.FilterPermissions("06-012").sub_title : null,
                router: "/full-reports",
                screen_code: "06-012",
            },
            {
                img: "sales-report.svg",
                title: this.$global.FilterPermissions("06-013") ? this.$global.FilterPermissions("06-013").sub_title : null,
                router: "/sales-reports",
                screen_code: "06-013",
            },
            {
                img: "expenses.svg",
                title: this.$global.FilterPermissions("06-009") ? this.$global.FilterPermissions("06-009").sub_title : null,
                router: "/expenses",
                screen_code: "06-009",
            },
            {
                img: "shift.svg",
                title: this.$global.FilterPermissions("06-020") ? this.$global.FilterPermissions("06-020").sub_title : null,
                router: "/list_shifts",
                screen_code: "06-020",
            },
            {
                img: "support.svg",
                title: "support",
                router: "/support",
                screen_code: "00-000",
            },
        ];
        // this.menuList = this.$store.state.screens;
        // console.log(this.menuList);
    },
    data: () => ({
        menuList: [],
        staticList: [],
    }),
    methods: {
        toggleSidebar() {
            this.$store.state.sidebarIsOpen = !this.$store.state.sidebarIsOpen;
        },
        getImgUrl(img) {
            var images = require.context("../assets/img/pos_svg");
            return images("./" + img);
        },
    },
    components: { CompanyDetailMenu }
};
</script>

<style scoped lang="scss">
.activeLink {
   // background-color: #01b1f0 !important;
   // color: #fff;
   opacity: 1 !important;
   font-weight: 500 !important;

   color: #212121;

   img:first-of-type {
      display: none;
   }
   img:last-of-type {
      display: block;
   }
}
span {
   img {
      width: 20px;
      height: 20px;
   }
   // img:last-of-type {
   //    display: none;
   // }
}
.v-list-item,
.v-list-item--link,
.v-list-item--active {
   // border-radius: 10px !important;
}
.theme--light .activeLink {
   border-left: 4px solid #45ade2;
   border-right: 0px solid #45ade2;
   background-color: #E6ECFF !important;
}
.theme--light.v-application--is-rtl  .activeLink {
   border-left: 0 solid #45ade2;
   border-right: 4px solid #45ade2;
   color: #45ade2;
}
.theme--dark .activeLink {
   border-left: 4px solid #66b4e3;
   border-right: 0px solid #66b4e3;
}
.theme--dark.v-application--is-rtl  .activeLink {
   border-left: 0 solid #66b4e3;
   border-right: 4px solid #66b4e3;
   color: #66b4e3;
}

.v-list-item img {
   filter: grayscale(100%) !important;
}
.v-list-item--active img {
   filter: grayscale(0%) !important;
}
.v-application--is-ltr .v-list-item__icon:first-child {
   margin-right: 12px !important;
}
.v-application--is-ltr
   .v-navigation-drawer--mini-variant
   .v-list-item__icon:first-child {
   margin-right: 0 !important;
}
.v-list-group .v-list-item__title {
   text-align: initial;
}
.v-list-group .v-list-item--link {
   text-align: initial;
   padding: 0 2.5rem;
}
.v-list-item--link {
   opacity: 0.7;
}
.v-application--is-rtl
   .v-navigation-drawer--mini-variant
   .v-list-item__icon:first-child {
   margin-right: 0 !important;
}
.v-application--is-rtl .v-list-item__icon:first-child {
   margin-left: 0;
   margin-right: 0;
}

.v-divider {
   opacity: 0.65 !important;
   width: 200px !important;
}
</style>