<template>
  <div>
    <v-main v-if="$store.state.splashScreen == false">
      <v-container  fluid>
        <v-row>
          <v-navigation-drawer class="shadow border-0 mt-15" style="z-index : 66" :right="$vuetify.rtl == true" mini-variant-width="80"
            v-model="$store.state.sidebarIsOpen" :mobile-breakpoint="1026" app>
            <Sidebar></Sidebar>
          </v-navigation-drawer>
            <AppBar />

          <v-container fluid class="pa-0 mt-16">
            <router-view></router-view>
          </v-container>
        </v-row>
      </v-container>
      <HotKeysDialog />
    </v-main>
  </div>
</template>


<script>
import AppBar from "@/components/AppBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import HotKeysDialog from "@/components/modals/HotKeysDialog.vue";
export default {
  name: "Dashboard",
  components: {
    AppBar,
    Sidebar,
    HotKeysDialog
},
  mounted() {
    this.darkTheme = this.$store.state.Settings.darkTheme;
    setInterval(() => {
      this.networkConnection = this.$api.CHECK_INTERNET();
      this.$store.state.networkConnection = this.$api.CHECK_INTERNET();
    }, 2000);

    window.addEventListener("keydown", e => {
      console.log('key', e.key);
      this.$store.commit('Settings/SET_KEY_CLICKED', e.key);
      setTimeout(() => {
        this.$store.commit('Settings/SET_KEY_CLICKED', null);
      }, 100);
      switch (e.key) {
        case 'F1':
        case 'F3':
        case 'F5':
        case 'F6':
        case 'F7':
        case 'F10':
          e.preventDefault();
          break;
        default:
          break;
      }
    });
  },
  data() {
    return {
      mini: false,
      darkTheme: false,
      networkConnection: Boolean
    }
  },
  methods: {
    darkThemee() {
      this.$store.dispatch("Settings/darkTheme");
    },
  },

}
</script>
<style scoped>
</style>