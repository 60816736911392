<template>
  <section>
    <v-container fluid>

      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="2" class="py-0">
            <div class="font-weight-bold"> {{ $global.FilterPermissions('06-013').sub_title | capitalize }} </div>
          </v-col>
          <v-col cols="10" class="py-2">
            <v-row align="center" justify="end">
              <GenericInput type="date" :clearable="true" :isPickerOpened="() => filter.to_date = null"
                :maxDate="filter.to_date || today" :dense="true" backgroundColor="white" :value="filter.from_date"
                :multi="true" paddingY="py-2 py-lg-0" paddingX="px-4 px-lg-3" @input="filter.from_date = $event"
                label="from" :isLoading="false" :hideDetails="true" :cols="[12, 4, 3]"></GenericInput>

              <GenericInput type="date" :clearable="true" :minDate="filter.from_date" :maxDate="today" :dense="true"
                backgroundColor="white" :value="filter.to_date" :multi="true" paddingY="py-2 py-lg-0"
                paddingX="px-4 px-lg-3" @input="filter.to_date = $event" label="to" :isLoading="false" :hideDetails="true"
                :cols="[12, 4, 3]">
              </GenericInput>

              <GenericInput type="select" :clearable="true" :dense="true" backgroundColor="white" :lookups="types"
                selected_label="title" paddingY="py-2 py-lg-0" paddingX="px-4 px-lg-3" selected_prop="id"
                :value="filter.report_type" :multi="false" @input="filter.report_type = $event" label="types"
                :isLoading="false" :hideDetails="true" :cols="[12, 4, 3]"></GenericInput>

              <GenericInput type="select" :clearable="true" :dense="true" backgroundColor="white" :lookups="payment_types"
                selected_label="name" paddingY="py-2 py-lg-0" paddingX="px-4 px-lg-3" selected_prop="id"
                :value="filter.payment_type" :multi="false" @input="filter.payment_type = $event" label="payment type"
                :isLoading="false" :hideDetails="true" :cols="[12, 4, 3]"></GenericInput>


              <GenericInput type="select" :clearable="true" :dense="true" backgroundColor="white" :lookups="sales_agents" :value="filter.sales_agent_id"
                @input="filter.sales_agent_id = $event" label="sales agent" paddingX="px-4 px-lg-3"  :multi="false" :required="false"
                selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false" :cols="[12, 6, 3]" />

              <GenericInput type="select" v-if="barbers.length" :clearable="true" :dense="true" backgroundColor="white" :lookups="barbers"
                :value="filter.barber_id" @input="filter.barber_id = $event" label="barber" paddingX="px-4 px-lg-3"  :multi="false"
                :required="false" selected_label="name" selected_prop="id" :hide-details="true" :isLoading="false"
                :cols="[12, 6, 3]" />

              <v-col cols="12" md="4" lg="auto" class="d-flex">
                <v-btn :disabled="rows.length == 0" :loading="printLoading" small @click="printValueMethod()" :height="35"
                  :width="100" class="shadow mx-2 rounded-lg white--text" color="accent">
                  {{ $t("print") }}
                  <v-icon size="18" right>mdi-printer</v-icon>
                </v-btn>

                <v-btn :loading="isLoading" small @click="getReports()" :height="35" :width="100"
                  class=" d-block mi-start-auto rounded-lg white--text" color="blue4" outlined>
                  {{ $t("search") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>



      <v-card class="mt-5 shadow overflow-hidden">
        <v-row align="center" justify="space-between">
          <v-col cols="auto" class="mb-2">
            <v-tabs v-model="tab" active-class="secondary white--text" color="secondary">
              <v-tab>{{ $t('reports') }}</v-tab>
              <v-tab :disabled="rows.length == 0">{{ $t('statistics') }}</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="auto" class="mx-5" v-if="tab == 1">
            <v-btn small @click="chartType = 1" fab :color="chartType == 1 ? 'primary' : 'gray12'" class="mx-1" depressed>
              <v-icon>mdi-chart-bell-curve</v-icon>
            </v-btn>
            <v-btn small @click="chartType = 2" fab :color="chartType == 2 ? 'primary' : 'gray12'" class="mx-1" depressed>
              <v-icon>mdi-chart-bar</v-icon>
            </v-btn>
            <v-btn small @click="chartType = 3" fab :color="chartType == 3 ? 'primary' : 'gray12'" class="mx-1" depressed>
              <v-icon>mdi-chart-donut</v-icon>
            </v-btn>
            <v-btn small @click="chartType = 4" fab :color="chartType == 4 ? 'primary' : 'gray12'" class="mx-1" depressed>
              <v-icon>mdi-chart-timeline</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-col cols="12" sm="12">
              <DynamicTable :isLoading="isLoading" :searchMethod="searchMethod" :controlRoute="controlRoute"
                :searchByDateRange="false" :option="options" :viewSearch="false" :editValueMethod="edit"
                printURL="sales_report" :tableHeight="$store.state.Settings.windowSize.y - 270"
                :printValueMethod="printValueMethod" btnIcon="mdi-share" :data="rows" :header="header"
                :footerData="footerData" noDataText="please select to view report">
                <template v-slot:td="{ row, header }">
                  <span class="d-flex justify-center" v-if="header.key !== 's_m_id' && header.key !== 'r_m_id'">
                    <span v-if="row.sumtion == 2"> ({{ row[header.key] || 0 | float }})</span>
                    <span v-else> {{ row[header.key] || 0 | float }}</span>
                  </span>
                  <span class="d-flex justify-center" v-if="header.key == 's_m_id'">
                    <v-hover v-slot="{ hover }" close-delay="150" open-delay="150">
                      <v-btn color="primary" min-width="90" :text="!hover" depressed small
                        @click="printInvoice(row, 's_m_id')" v-if="row[header.key]"> {{ hover ? $t('print') :
                          row[header.key] || '-' }}</v-btn>
                    </v-hover>
                  </span>
                  <span class="d-flex justify-center" v-if="header.key == 'r_m_id'">
                    <v-hover v-slot="{ hover }" close-delay="150" open-delay="150">
                      <v-btn color="primary" min-width="90" :text="!hover" depressed small
                        @click="printInvoice(row, 'r_m_id')" v-if="row[header.key]"> {{ hover ? $t('print') :
                          row[header.key] || '-' }}</v-btn>
                    </v-hover>
                  </span>
                </template>
              </DynamicTable>
            </v-col>
          </v-tab-item>
          <v-tab-item v-if="rows.length > 0">

            <v-col cols="12" sm="12" class="pa-0">

              <v-card flat relative :height="$store.state.Settings.windowSize.y - 250">
                <SalesChart v-if="chartType == 2" :key="analysisKey" :quantities="quantities" :summary="summary"
                  :summary_categories="summary_categories" />

                <SalesChartCurve v-if="chartType == 1" :key="analysisKey" :quantities="quantities" :summary="summary"
                  :summary_categories="summary_categories" />

                <SalesChartDonut v-if="chartType == 3" :key="analysisKey" :quantities="quantities" :summary="summary"
                  :summary_categories="summary_categories" />

                <SalesChartBar v-if="chartType == 4" :key="analysisKey" :quantities="quantities" :summary="summary"
                  :summary_categories="summary_categories" />
              </v-card>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

    </v-container>
  </section>
</template>



<script>
import DynamicTable from "@/components/DynamicTable";
// import Pagination from "@/components/Pagination";
import GenericInput from "@/components/GenericInput.vue";
import SalesChart from "./SalesChart.vue";
import SalesChartBar from "./SalesChartBar.vue";
import SalesChartCurve from "./SalesChartCurve.vue";
import SalesChartDonut from "./SalesChartDonut.vue";
export default {
  name: "SalesReportsEntity",

  data: () => ({
    isLoading: true,
    controlRoute: null,
    optionDialog: false,
    printLoading: false,
    selectedRow: null,
    options: null,
    rows: [],
    header: [],
    pagination: null,
    page: 1,
    rowsNumber: 15,
    searchText: '',
    from: null,
    to: null,
    today: null,
    date: null,
    tab: 0,
    branches: [],
    categories: [],
    customers: [],
    locations: [],
    payment_types: [],
    services: [],
    status: [],
    times: [],
    types: [],
    barbers: [],
    summary: [],
    analysisKey: 1,
    chartType: 1,
    summary_categories: [],
    quantities: [],

    filter: {
      services: [],
      report_type: 0,
      payment_type: 0,
      from_date: "",
      to_date: "",
    },
    footerData: [{
      invoice_code: null,
      branch_title: null,
      customer_name: null,
      service_title: null,
      barber_id: null,
      sales_agent_id: null,
      qty: 0,
      id: null,
    }],
  }),
  components: {
    DynamicTable,
    SalesChart,
    SalesChartCurve,
    GenericInput,
    SalesChartDonut,
    SalesChartBar
  },
  watch: {
    rows() {
      this.footerData[0].s_m_id = this.$i18n.t('total');
      this.footerData[0].total_without_vat = this.totalPriceWithoutVat;
      this.footerData[0].payment_type = this.rowsLength;
      this.footerData[0].total_vat = this.totalVAT;
      this.footerData[0].total_quantity = this.totalQTY;
      this.footerData[0].total_price = this.totalPrice;
      this.footerData[0].discount = this.totalDiscount;
      this.footerData[0].paid_bank = this.paidBank;
      this.footerData[0].paid_cash = this.paidCash;
      this.footerData[0].term_sales = this.termSales;
      this.footerData[0].total_with_vat = this.totalGrandPrice;
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F5':
          this.getReports();
          break;
        default:
          break;
      }
    },
  },
  computed: {
    termSales() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.term_sales);
          case 2:
            return Number(+oldValue) - Number(+newValue.term_sales);
        }
      }, 0);
    },
    totalGrandPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_with_vat);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_with_vat);
        }
      }, 0);
    },
    totalQTY() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_quantity);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_quantity);
        }
      }, 0);
    },
    paidCash() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.paid_cash);
          case 2:
            return Number(+oldValue) - Number(+newValue.paid_cash);
        }
      }, 0);
    },
    paidBank() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.paid_bank);
          case 2:
            return Number(+oldValue) - Number(+newValue.paid_bank);
        }
      }, 0);
    },
    totalPrice() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_with_vat);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_with_vat);
        }
      }, 0);
    },
    totalDiscount() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.discount);
          case 2:
            return Number(+oldValue) - Number(+newValue.discount);
        }
      }, 0);
    },
    rowsLength() {
      return this.rows.length
    },
    totalVAT() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_vat);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_vat);
        }
      }, 0);
    },
    totalPriceWithoutVat() {
      return this.rows.reduce((oldValue, newValue) => {
        switch (newValue.sumtion) {
          case 1:
            return Number(+oldValue) + Number(+newValue.total_without_vat);
          case 2:
            return Number(+oldValue) - Number(+newValue.total_without_vat);
        }
      }, 0);
    },
  },
  mounted() {
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.filter.to_date = this.$global.GetCurrentDate();
    this.filter.from_date = this.$global.GetFirstDayOfMonth();
    this.payment_types = [
      {
        id: 0,
        name: this.$i18n.t('all')
      },
      {
        id: 1,
        name: this.$i18n.t('cash')
      },
      {
        id: 2,
        name: this.$i18n.t('network')
      },
    ]
    this.getData();
  },
  methods: {
    pageMainData() {
      this.header = [
        { text: "invoice number", key: "s_m_id", type: 'slot', classes: "", },
        { text: "notice invoice", key: "r_m_id", type: 'slot', classes: "" },
        { text: "invoice date", key: "invoice_date", type: 'date', width: 120, classes: "" },
        { text: "type", key: "type_title", type: 'text', classes: "" },
        { text: "payment type", key: "payment_type", type: 'text', classes: "" },
        { text: "cash", key: "paid_cash", type: 'non_zero_float', classes: "" },
        { text: "bank", key: "paid_bank", type: 'non_zero_float', classes: "" },
        { text: "term", key: "term_sales", type: 'non_zero_float', classes: "" },
        { text: "customer", key: "customer_name", type: 'text', classes: "" },
        { text: "discount", key: "discount", type: 'non_zero_float', classes: "" },
        { text: "quantity", key: "total_quantity", type: 'number', classes: "" },
        { text: "price without vat", key: "total_without_vat", type: 'non_zero_float', classes: "" },
        { text: "vat", key: "total_vat", type: 'non_zero_float', classes: "" },
        { text: "total with vat", key: "total_with_vat", type: 'non_zero_float', classes: "", dir: 'ltr' },
      ];
      this.options = {
        print: true,
        delete: false,
        edit: false,
        restore: false,
        view: false,
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
    },
    optionBackMethod(status) {
      console.log(status);
      this.optionDialog = false;
      this.selectedRow = null;
    },
    getData() {
      this.isLoading = true;
      this.$api.GET_METHOD(`sales_report`).then((response) => {
        this.isLoading = false;
        if (response.check) {
          // this.services = response.data.services;
          this.categories = response.data.categorys;
          this.locations = response.data.locations;
          this.branches = response.data.branchs;
          this.types = response.data.types;
          this.barbers = response.data.barbers || [];
          this.sales_agents = response.data.sales_agents || [];
          if (this.barbers.length) {
            this.pageData.tableHeader.splice(8, 0,
              { text: "barber", key: "barber_name", type: 'text', classes: "" },
              { text: "booking barber", key: "booking_barber_name", type: 'text', classes: "" }
            )
          }
        }
      })

    },
    getReports() {
      this.isLoading = true;
      this.summary_categories = [];
      this.summary = [];
      this.quantities = [];
      this.tab = 0;
      this.rows = [];
      const body = {
        from: this.filter.from_date,
        to: this.filter.to_date,
        report_type: this.filter.report_type,
        payment_type: this.filter.payment_type,
        type: 'json'
      }
      this.$api
        .POST_METHOD(`sales_report`, body, false)
        .then((response) => {
          this.isLoading = false;
          if (response.check) {
            response.data.data.forEach(row => {
              row.type_title = this.types[Number(row.type)].title;
            })
            this.reportsRow = response.data.data;
            Object.keys(response.data.chart).forEach(date => {
              this.summary_categories.push(
                new Date(date).toLocaleDateString("en-UK", {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
              );
              this.summary.push(+response.data.chart[date]['total_with_vat'].toFixed(2));
              this.quantities.push(+response.data.chart[date]['quantity']);
            });
            this.rows = response.data.data;
            this.analysisKey = this.analysisKey + 1;
            // this.pageData.account_tree = response.data.account_tree;
          }
        });
    },
    edit(edit) {
      console.log(edit);
      this.$router.push(`${this.controlRoute}/` + edit.id)
    },
    printInvoice(row, type) {
      switch (type) {
        case 's_m_id':
          this.$api.PrintInvoiceURL(`${this.$api.serverUrl}print_sales_invoice/${row.s_m_id}?type=print&clientID=${this.$store.state.clientID}`, row.s_m_id)
          break;
        case 'r_m_id':
          this.$api.PrintInvoiceURL(`${this.$api.serverUrl}${+row.type == 3 ? 'export_notice_debtor' : 'export_notice_creditor'}/${row.r_m_id}?type=print&clientID=${this.$store.state.clientID}`, row.r_m_id)
          break;
        default:
          break;
      }
    },
    printValueMethod() {
      this.printLoading = true;
      const body = {
        from: this.filter.from_date,
        to: this.filter.to_date,
        report_type: this.filter.report_type,
        payment_type: this.filter.payment_type,
        type: 'pdf'
      }
      this.$api
        .DOWNLOAD_METHOD_BODY(`sales_report`, body, `sales_report.pdf`)
        .then(() => { this.printLoading = false; });


      // const src = `src="${this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/sales_report/` + row.id}?type=image"`;
      // this.$api.PrintInvoice(src, row.id)
      // window.open(this.$store.state.endpointURL + `api/v1/${this.$store.state.Settings.language}/show_print_receipt_by_invoice/` + row.id);
    },
    searchMethod(text, date, from, to) {
      this.searchText = text;
      this.from = from;
      this.to = to;
      this.date = date;
      this.getReports()
    },
    changePage(page, limit) {
      this.page = page;
      this.rowsNumber = limit;
      this.getData();
    },
  },
};
</script>
