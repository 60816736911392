<template>
  <section>
    <v-container fluid>
      <v-col cols="12" class="pt-0 mb-3">
        <v-row justify="space-between" align="center">
          <v-col cols="auto" class="d-none d-lg-block">
            <div class="font-weight-bold"> {{ $global.FilterPermissions('06-009').sub_title | capitalize}}</div>
          </v-col>
          <GenericInput type="date" :keydownEnter="getData"  paddingX="px-1" backgroundColor="white" prefix="from date" dense :value="from_date"  :maxDate="to_date || today"
            @input="from_date = $event" label="" :required="false" :hide-details="true"
            :isLoading="pageData.isLoading" :cols="[6, 3, 3]">
          </GenericInput>
          <GenericInput type="date"  :keydownEnter="getData" paddingX="px-1" backgroundColor="white" prefix="from date" dense :value="to_date"  :minDate="from_date"
            :maxDate="today" @input="to_date = $event" label="" :required="false" :hide-details="true"
            :isLoading="pageData.isLoading" :cols="[6, 3, 3]">
          </GenericInput>
          <v-col cols="auto" md="2" class="px-1">
            <v-text-field background-color="white" v-model="pageData.search"  :loading="pageData.isLoading"
              @keydown.enter="(e) => getSearch(e.target.value)" @click:clear="(e) => getSearch(e.target.value)"
              @click:append="getSearch(pageData.search)" append-icon="mdi-magnify" :placeholder="
                pageData.isTrashed == 0 ? $i18n.t('Search in deleted') : $i18n.t('search')
              " single-line clearable hide-details outlined dense :height="38"></v-text-field>
          </v-col>
          <v-col cols="auto" class="px-1 d-flex">
            <v-btn  depressed :loading="pageData.isLoading" @click="getSearch(pageData.search)" :height="37" :width="100"
            color="success" class="rounded-lg mx-1">
            {{ $t("search") }}
          </v-btn>
            <v-btn  depressed :loading="pageData.isLoading" @click="$router.push('/expenses-control')" :height="37" :width="100"
            color="gray3" class="rounded-lg white--text">
            {{ $t("add") }}
          </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-row align="center" justify="center">
        <v-col cols="12" class="py-0">
          <v-card class="elevation-0 pa-0 rounded-md" color="transparent">
            <DynamicTable :tableHeight="$store.state.Settings.windowSize.y - 200" :viewTitle="false"
              :isLoading="pageData.isLoading" :data="pageData.rows" :header="pageData.tableHeader"
              :option="pageData.options"  tableTitle="requests list" :pageData="pageData">
              <template v-slot:td="{ row, header }">
                <div v-if="header.key == 'actions'">
                  <v-btn @click="print(row)" x-small icon fab><v-icon>mdi-printer</v-icon></v-btn>
                  <!-- <v-btn @click="showInvoice(row)" small icon fab><v-icon>mdi-eye</v-icon></v-btn> -->
                </div>
             </template>
            </DynamicTable>
          </v-card>
        </v-col>

          <v-col cols="12" sm="12" v-if="!pageData.isLoading && pageData.rows.length > 0">
            <Pagination :pagination="pageData.pagination" :limit="pageData.rowsNumber" :backValueMethod="changePage"
              :isLoading="pageData.isLoading"></Pagination>
          </v-col>

      </v-row>

    </v-container>
  </section>
</template>

<script>

import DynamicTable from '@/components/DynamicTable.vue';
import GenericInput from '@/components/GenericInput.vue';
import Pagination from '@/components/Pagination.vue';

export default {
  name: "ExpensesEntity",
  computed: {

  },
  components: {
    DynamicTable,
    GenericInput,
    Pagination
},

  data: () => ({
    pageData: {
      screen_code: "06-009",
      url: '/expenses',
      controlRoute: null,
      entityName: null,
      main: null,
      category: null,
      isLoading: true,
      isTrashed: 1,
      rowsNumber: 10,
      tableHeader: [],
      search: "",
      rows: [],
      options: {},
      page: 1,
      pagination: {},
      controlDialog: false,
      controlID: null,
      queryParam: '?'
    },
    from_date: null,
    to_date: null,
    today: null,
    view_type: 1,
    types: [],
  }),
  watch: {
    $route() {
      this.pageData.queryParam = '?';
      this.queryParams();
      this.pageMainData();
      this.getData();
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F5':
          this.getData();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    if (this.$global.CheckAction(this.pageData.screen_code, 1)) {
    this.queryParams();
    this.pageMainData();
    this.today = this.$global.GetCurrentDate();
    this.from_date = this.$global.GetFirstDayOfMonth();
    this.to_date = this.$global.GetCurrentDate();
    this.getData();
    } else {
      this.$router.push("/");
      this.$global.ShowAlert('info', this.$i18n.t('no permission'), false, this.$i18n.t('check permission with admin'))
    }
  },
  methods: {
    queryParams() {
      this.pageData.isTrashed = Number(this.$store.state.activeScreen.queryParams.active);
      this.pageData.page = Number(this.$store.state.activeScreen.queryParams.page) || 1;
      this.pageData.rowsNumber = Number(this.$store.state.activeScreen.queryParams.rows) || 10;
      this.pageData.search = this.$store.state.activeScreen.queryParams.word || '';
      this.pageData.queryParam = this.$store.state.activeScreen.requestParam || '';
    },
    pageMainData() {
      this.pageData.tableHeader = [
        { text: "invoice number", key: "id", type: 'text', classes: "" },
        { text: "pay for", key: "pay_for", type: 'text', classes: "" },
        { text: "description", key: "description", type: 'text', classes: "" },
        { text: "expenses amount", key: "amount", type: 'price', classes: "" },
        { text: "print", key: "actions", type: 'slot', classes: "" },
      ];
      this.pageData.options = {
        print: this.$global.CheckAction(this.pageData.screen_code, 5),
        delete: this.$global.CheckAction(this.pageData.screen_code, 4),
        edit: this.$global.CheckAction(this.pageData.screen_code, 3),
        restore: this.$global.CheckAction(this.pageData.screen_code, 4),
        view: this.$global.CheckAction(this.pageData.screen_code, 1),
        switch: false,
        selectRow: false,
        sortBy: "id",
        tableHeader: false,
        searchInput: false,
        printButton: false,
        handleClickRow: false,
      };
      this.types =[{ name: this.$i18n.t('view all'), id: 1 }, { name: this.$i18n.t('notice debtor'), id: 2 }, { name: this.$i18n.t('notice creditor'), id: 3 }]
    },
    getSearch(word) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${this.pageData.rowsNumber}&page=${this.pageData.page}&word=${word || ''}&from_date=${this.from_date || ''}&to_date=${this.to_date || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    getData() {
      this.pageData.isLoading = true;
      this.$api
        .GET_METHOD(`expense${this.pageData.queryParam}&from_date=${this.from_date}&to_date=${this.to_date}`)
        .then((response) => {
          this.pageData.isLoading = false;
          if (response.check) {
            this.pageData.entityName =
              this.pageData.isTrashed == 0
                ? this.$i18n.t("deleted from") + " " + this.$store.state.activeScreen.sub_title
                : this.$store.state.activeScreen.sub_title;
            this.pageData.rows = response.data.items.data;
            this.pageData.page = response.data.items.current_page;
            this.pageData.pagination = {
              page: response.data.items.current_page,
              totalPages: response.data.items.last_page,
              per_page: response.data.items.per_page,
              totalRows: response.data.items.total,
            };

          }
        });
    },
    showInvoice(row) {
      this.$router.push({
        path: `/expenses-control/${row.m_id}`,
        query : { m_id: row.m_id , prevScreenQueryParam :  this.pageData.queryParam ,prevRoute: '/expenses'},
      });
    },
    changePage(page, limit) {
      this.pageData.queryParam = `?active=${this.pageData.isTrashed}&rows=${limit}&page=${page}&word=${this.pageData.search  || ''}&from_date=${this.from_date  || ''}&to_date=${this.to_date  || ''}`
      this.$router.push(this.pageData.url + (this.pageData.queryParam || ''))
    },
    print(row) {
      const src = `src="${this.$api.serverUrl}export_expense/${row.id}?type=image&clientID=${this.$store.state.clientID}"`;
      this.$api.PrintInvoice(src,row. m_id)
    },
  },

};
</script>
