<template>
  <v-card class="elevation-0 justify-center position-relative my-0 pa-0 rounded-0 table_full"
    :color="data.length > 0 ? 'transparent' : 'transparent'"
    :class="data.length > 0 ? ' ' + cardClasses : ' ' + cardClasses">

    <v-row justify-md="space-between" justify="center" align="center" v-if="viewTitle">
      <v-col cols="auto" md="auto" class="px-8 pb-5 d-flex align-center">
        <h4>{{ $t(tableTitle) | capitalize }}</h4>
      </v-col>
      <v-col cols="12" md="auto" class="px-8 pb-5">
        <slot name="inputs"></slot>
      </v-col>
    </v-row>
    <v-col cols="12" sm="12" class="pa-0 position-relative" v-if="data.length > 0">
      <div id="tableExtensions" class="mt-2" v-if="data.length > 0 && !isLoading"
        :class="{ 'extensions-active': extensions }" @click.passive="extensions = !extensions">
        <div class="dots">...</div>
        <div class="extensions-list">

          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small color="primary" class="mx-1" @click="changeTableSizeMethod">
                <v-icon size="28" v-if="$store.state.tableLarge">mdi-table-large</v-icon>
                <v-icon size="28" v-if="!$store.state.tableLarge">mdi-table</v-icon>
              </v-btn>
            </template>
            <span class="caption"> {{ $t('change table size') }}</span>
          </v-tooltip>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small color="primary" class="mx-1" v-fullscreen="full_screen_options">
                <v-icon size="28" v-if="!fullscreen">mdi-fullscreen</v-icon>
                <v-icon size="28" v-if="fullscreen">mdi-fullscreen-exit</v-icon>
              </v-btn>
            </template>
            <span class="caption"> {{ $t('full screen') }}</span>
          </v-tooltip>
          <v-divider class="mx-2" vertical />
          <v-tooltip bottom color="green">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small color="green" class="mx-1" @click="exportExcel">
                <img src="@/assets/img/png/files/xlsx.svg" :height="25" alt="">
              </v-btn>
            </template>
            <span class="caption"> {{ $t('download') }}</span>
          </v-tooltip>
          <v-tooltip bottom color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon small color="error" class="mx-1" @click="exportPDF">
                <img src="@/assets/img/png/files/pdf.png" :height="25" alt="">
              </v-btn>
            </template>
            <span class="caption"> {{ $t('download') }}</span>
          </v-tooltip>
        </div>
      </div>
      <v-simple-table :fixed-header="fixedHeader" :height="data.length >= 10 ? tableHeight : 'auto'"
        :dense="!$store.state.tableLarge" v-if="!isLoading">
        <template v-slot:default>
          <thead>
            <tr v-if="showGroupHeader">
              <th v-for="(group, groupIndex) in groupHeader" :key="groupIndex" :rowspan="group.rowspan"
                :colspan="group.colspan" class="text-center font-weight-bold">{{ $t(group.text) | capitalize }}</th>
            </tr>
            <tr>
              <th v-if="selectRow" class="d-flex align-center"></th>
              <th v-for="(header, index) in header" class="text-center" :key="index"
                :style="'min-width:' + header.width + 'px'">{{ $t(header.text) | capitalize }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in data" :key="index" :class="row.rowClass" @click="rowClicked(row)">
              <td v-if="selectRow" class="d-flex align-center">
                <v-checkbox color="blue4" class="d-block ma-auto" @change="checkboxMethod(selectedRow)" hide-details
                  v-model="selectedRow" :value="row" />
              </td>
              <td class="text-center" v-for="(header, headerIndex) in header" :style="'min-width:' + header.width + 'px'"
                :class="header.classes + ' ' + row.rowClass" :key="headerIndex">
                <!-- text -->
                <span v-if="header.type == 'text'">{{ row[header.key] || '-' }}</span>

                <!-- float -->
                <div v-else-if="header.type == 'float'" dir="ltr" :class="{ 'error--text': row[header.key] < 0 }">
                  {{ row[header.key] | nonZero }}
                </div>
                <!-- abs -->
                <div v-else-if="header.type == 'abs'" dir="ltr" :class="{ 'success--text': row[header.key] < 0 }">
                  {{ row[header.key] | abs }}
                </div>
                <!-- non_zero_float -->
                <div v-else-if="header.type == 'non_zero_float'" dir="ltr"
                  :class="{ 'error--text': row[header.key] < 0 }">
                  <template v-if="row.isCredit && row[header.key] > 0">
                    ({{ row[header.key] | nonZero }})
                  </template>
                  <template v-else>
                    {{ row[header.key] | nonZero }}
                  </template>
                </div>

                <!-- number -->
                <span v-else-if="header.type == 'number'">{{ +row[header.key] || 0 }}</span>
                <!-- percentage -->
                <span v-else-if="header.type == 'percentage'">{{ row[header.key] }} %</span>
                <!-- price -->
                <span v-else-if="header.type == 'price'">{{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2))
                  || '-'
                }}
                  {{ $t('sar') }}</span>
                <!-- weight -->
                <span v-else-if="header.type == 'weight'">{{ $global.DecimalFormatter((row[header.key] || 0).toFixed(2))
                  || '-'
                }}
                  {{ $t('gram') }}</span>
                <!-- pieces  -->
                <span v-else-if="header.type == 'pieces'">{{ row[header.key] || '-' }} {{ $t('pieces') }}</span>
                <!-- img  -->
                <span v-else-if="header.type == 'img'">
                  <v-avatar size="35" class="rounded-0">
                    <v-img v-if="row[header.key]" :src="$store.state.endpointURL + row[header.key]" alt="" />
                    <v-img v-if="!row[header.key]" src="@/assets/img/png/files/notFound.png" alt="" />
                  </v-avatar>
                </span>
                <!-- img base -->
                <span v-else-if="header.type == 'img_base64'">
                  <v-avatar size="35" class="rounded-0">
                    <v-img v-if="row[header.key]" :src="row[header.key]" alt="" />
                    <v-img v-if="!row[header.key]" src="@/assets/img/png/files/notFound.png" alt="" />
                  </v-avatar>
                </span>
                <!-- array -->
                <span v-else-if="header.type == 'array' && row[header.key]">
                  <div v-for="(text, index) in row[header.key].split(',').map(i => i)" :key="index" class="mx-1">
                    {{ text }}
                  </div>
                </span>
                <!-- imgs -->
                <span v-else-if="header.type == 'imgs' && row[header.key]">
                  <v-avatar size="35" class="mr-n3 rounded-circle  white"
                    v-for="(img, index) in row[header.key].split(',').map(i => i)" :key="index">
                    <v-tooltip bottom color="success">
                      <template v-slot:activator="{ on, attrs }">
                        <v-img v-bind="attrs" v-on="on" :src="$store.state.endpointURL + img" alt="" />
                      </template>
                      <span v-if="row.users_names">{{ row.users_names.split(',').map(i => i)[index] }}</span>
                    </v-tooltip>
                  </v-avatar>
                </span>
                <!-- slot -->
                <span v-else-if="header.type == 'slot'" class="d-flex justify-center">
                  <slot name="td" :row="row" :header="header" :index="index"></slot>
                </span>
                <!-- button -->
                <span v-else-if="header.type == 'button'">
                  <v-btn @click="buttonValueMethod(row)" small depressed
                    :class="header.buttonClass + ' ' + row['button_color']">
                    {{ $t(row[header.key]) }}
                  </v-btn>
                </span>
                <!-- checkbox -->
                <span v-else-if="header.type == 'checkbox'" class="d-flex align-center">
                  <v-checkbox v-model="row[header.key]" color="blue4" class="d-block ma-auto" hide-details />
                </span>
                <!-- delete  -->
                <span v-else-if="header.type == 'delete'">
                  <v-tooltip bottom color="error">
                    <template v-slot:activator="{ on, attrs }">
                      <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                        @click="deleteValueMethod(row, index)" src="@/assets/img/svg/delete_icon.svg" alt="qawaem erp" />
                    </template>
                    <span>{{ $t('delete') }}</span>
                  </v-tooltip>
                </span>

                <!-- date -->
                <span v-else-if="header.type == 'date'"> {{ row[header.key] || '-' }}</span>
                <!-- Switch -->
                <span v-else-if="header.type == 'switch'" class="d-flex justify-center align-center">
                  <v-switch inset @mouseup="toggleActiveMethod(row)" color="success" hide-details class="d-block ma-auto"
                    v-model="row[header.key]"></v-switch>
                </span>
                <!-- actions -->
                <span v-else-if="header.type == 'actions' && option">
                  <div class="d-flex align-center justify-center">
                    <!-- print -->
                    <span v-if="option.print">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" v-if="pageData.printLoading"
                            :loading="(pageData.printLoading.loading == true && pageData.printLoading.id == row[pageData.printLoading.key])"
                            @click="printValueMethod(row)" icon>
                            <v-icon color="info">mdi-printer</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('print') }}</span>
                      </v-tooltip>
                    </span>
                    <span v-if="option.edit && isLoading == false">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                            @click="editValueMethod(row)" src="@/assets/img/svg/pen.svg" alt="qawaem erp" />
                        </template>
                        <span>{{ $t('edit') }}</span>
                      </v-tooltip>
                    </span>
                    <span v-if="option.view && !option.edit && isLoading == false">
                      <v-tooltip bottom color="primary">
                        <template v-slot:activator="{ on, attrs }">
                          <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                            @click="editValueMethod(row)" src="@/assets/img/svg/eye-2.svg" alt="qawaem erp" />
                        </template>
                        <span>{{ $t('show') }}</span>
                      </v-tooltip>
                    </span>
                    <span v-if="option.delete && pageData.isTrashed == 1 && isLoading == false">
                      <v-tooltip bottom color="error">
                        <template v-slot:activator="{ on, attrs }">
                          <img class="mx-2 cursor_pointer" style="height: 18px;" v-bind="attrs" v-on="on"
                            @click="dialogDeleteItem = row, deleteDialog = true" src="@/assets/img/svg/delete_icon.svg"
                            alt="qawaem erp" />
                        </template>
                        <span>{{ $t('delete') }}</span>
                      </v-tooltip>
                    </span>
                    <span v-if="option.restore && pageData.isTrashed == 0 && isLoading == false">
                      <v-tooltip bottom color="light">
                        <template v-slot:activator="{ on, attrs }">
                          <img class="mx-2 cursor_pointer" style="height: 20px;" v-bind="attrs" v-on="on"
                            @click="restoreValueMethod(row)" src="@/assets/img/svg/restore.svg" alt="qawaem erp" />
                        </template>
                        <span class="primary--text">{{ $t('restore') }}</span>
                      </v-tooltip>
                    </span>

                  </div>
                </span>
                <span v-else>-</span>
              </td>
              <div style="" class="tooltipDetails light" v-if="tooltipRow == true">
                <div class="black--text d-flex justify-space-between mb-2"
                  v-for="(tooltipHeader, tooltipIndex) in tooltipTableHeader" :key="tooltipIndex">
                  <div class="font-weight-bold"> {{ $t(tooltipHeader.text) | capitalize }} :</div>
                  <div>
                    <span v-if="tooltipHeader.type == 'text'">{{ row[tooltipHeader.key] || '-' }}</span>
                    <span v-if="tooltipHeader.type == 'float'">{{ row[tooltipHeader.key] || '0' | float }}</span>
                  </div>
                </div>
              </div>


            </tr>
          </tbody>
          <tfoot v-if="footerData">
            <tr v-for="(row, index) in footerData" :key="index">
              <td v-if="selectRow" class="d-flex align-center sticky_header">
                <v-checkbox color="blue4" class="d-block ma-auto" @change="checkboxMethod(selectedRow)" hide-details
                  v-model="selectedRow" :value="row" />
              </td>
              <td class="text-center light sticky_header" v-for="(header, headerIndex) in header" :class="header.classes"
                :key="headerIndex">
                <!-- text -->
                <span v-if="header.type == 'text'" class="font-weight-bold ">{{ row[header.key] || '-' }}</span>
                <!-- float -->
                <div v-else-if="header.type == 'float'" class="font-weight-bold" dir="ltr">{{
                  $global.DecimalFormatter((row[header.key] || 0).toFixed($store.getters.decimal_digits))
                  || '-'
                }}</div>
                <div v-else-if="header.type == 'non_zero_float'" class="font-weight-bold" dir="ltr"
                  :class="{ 'error--text': row[header.key] < 0 }">
                  {{ row[header.key] | nonZero }}
                </div>
                <!-- price -->
                <span v-else-if="header.type == 'price'" class="font-weight-bold">{{
                  $global.DecimalFormatter((row[header.key] || 0).toFixed(2))
                  || '-'
                }}
                  {{ $t('sar') }}</span>
                <!-- weight -->
                <span v-else-if="header.type == 'weight'" class="font-weight-bold">{{
                  $global.DecimalFormatter((row[header.key] || 0).toFixed(2))
                  || '-'
                }}
                  {{ $t('gram') }}</span>
                <span v-else-if="header.type == 'number'" class="font-weight-bold">
                  {{ + row[header.key] || 0 }}
                </span>
                <!-- pieces  -->
                <span v-else-if="header.type == 'pieces'" class="font-weight-bold">{{ row[header.key] || '-' }} {{
                  $t('pieces')
                }}</span>
                <!-- slot -->
                <span v-if="header.type == 'slot'" class="font-weight-bold ">{{ row[header.key] || '-' }}</span>
                <!-- button -->
                <span v-else-if="header.type == 'button'" class="font-weight-bold">
                  <v-btn @click="buttonValueMethod(row)" small depressed
                    :class="header.buttonClass + ' ' + row['button_color']">
                    {{ $t(row[header.key]) }}
                  </v-btn>
                </span>
                <!-- checkbox -->
                <span v-else-if="header.type == 'checkbox'" class="d-flex align-center">
                  <v-checkbox v-model="row[header.key]" color="blue4" class="d-block ma-auto" hide-details />
                </span>
                <!-- date -->
                <span v-else-if="header.type == 'date'" class="font-weight-bold">
                  <span v-if="row[header.key]">{{
                    new Date(row[header.key]).toLocaleDateString(
                      `${$vuetify.rtl == true ? "ar" : "en-UK"}`,
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      }
                    )
                    || '-'
                  }}</span> <span v-else>-</span>
                </span>

                <span v-else></span>
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>

    </v-col>
    <!-- No data -->
    <div v-if="data.length == 0 && !isLoading" class="my-3">
      <img src="@/assets/img/svg/no_data.svg" height="150" class="d-block my-3 mx-auto" alt="qawaem">
      <div class="text-h6 text-center  gray8--text">{{ $t(noDataText) | capitalize }}</div>
    </div>
    <v-row justify="center" v-if="isLoading">
      <v-col cols="auto" class="my-15">
        <AnimatedLoading classes="pt-2  pa-5" :isLoading="isLoading" height="100" :hideText="false" />
      </v-col>
    </v-row>
    <DeleteConfirmation v-if="deleteDialog == true" :dialog="deleteDialog"
      :item="dialogDeleteItem.name ? dialogDeleteItem.name : null" :backValueMethod="deleteValue">
    </DeleteConfirmation>


    <slot name="afterTable"></slot>


    <div style="position: absolute; top: -100000000000px;">
      <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
        :paginate-elements-by-height="$store.state.Settings.windowSize.x"
        :filename="pageData ? pageData.entityName : $i18n.t(attachmentFileName)" :pdf-quality="1.5"
        :manual-pagination="true" pdf-format="a4" pdf-orientation="portrait"
        :pdf-content-width="$store.state.Settings.windowSize.y" @beforeDownload="() => download_loading = true"
        @hasDownloaded="() => download_loading = false" ref="html2Pdf">
        <section slot="pdf-content" :dir="$vuetify.rtl ? 'rtl' : 'ltr'">
          <!-- :html-to-pdf-options="htmlToPdfOptions" -->
          <PrintPDF :tableHeight="'auto'" :header="header" :maxPDFRows="maxPDFRows"
            :customize_header_footer="customize_header_footer" :data="data" :printStaticData="printStaticData"
            :header_slot="header_slot" :footer_slot="footer_slot" :showGroupHeader="showGroupHeader"
            :groupHeader="groupHeader" :footerData="footerData">
            <template v-slot:pdf_header="{ page, pages_count, isLastPage }">
              <slot name="pdf_header" :page="page" :pages_count="pages_count" :isLastPage="isLastPage" />
            </template>
            <template v-slot:pdf_footer="{ page, pages_count, isLastPage }">
              <slot name="pdf_footer" :page="page" :pages_count="pages_count" :isLastPage="isLastPage" />
            </template>
            <template v-slot:td="{ row, header }">
              <slot name="td" :row="row" :header="header" :index="index"></slot>
            </template>
          </PrintPDF>
        </section>
      </vue-html2pdf>
    </div>

  </v-card>
</template>





<script>
// import GenericInput from "./GenericInput";
import DeleteConfirmation from "./modals/DeleteConfirmation.vue";
import AnimatedLoading from "./AnimatedLoading.vue";
import xlsx from "json-as-xlsx";
import VueHtml2pdf from 'vue-html2pdf'
import PrintPDF from "./PrintPDF.vue";
export default {
  name: "DynamicTable",
  computed: {
    searchValid() {
      if (this.searchText || this.date || this.fromDate || this.toDate) {
        return true
      }
      else {
        return false
      }
    },
    full_screen_options() {
      return {
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
        target: ".table_full",
        pageOnly: this.pageOnly,
        teleport: this.teleport,
      };
    },
  },
  components: {
    // GenericInput,
    DeleteConfirmation,
    AnimatedLoading,
    VueHtml2pdf,
    PrintPDF,
  },
  watch: {
    fromDate() {
      if (this.viewSearch) {
        this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
      }
    },
    toDate() {
      if (this.viewSearch) {
        this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
      }
    },
    date() {
      if (this.viewSearch) {
        this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
      }
    },
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F2':
          if (!this.download_loading) {
            this.exportPDF();
          }
          break;
        case 'F3':
          if (!this.download_loading) {
            this.exportExcel();
          }
          break;
        default:
          break;
      }
    },
  },
  props: {
    searchMethod: { type: Function },
    rowClicked: { type: Function },
    isLoading: { default: false },
    pageData: { default: null },
    option: { default: null },
    changeTableSize: { default: true },
    viewSearch: { default: false },
    searchByDate: { default: false },
    searchByDateRange: { default: false },
    viewTitle: { default: false },
    data: { default: [] },
    header: { default: [] },
    printURL: { default: null },
    tableTitle: { default: null },
    controlRoute: { default: null },
    noDataText: { default: "you haven't entered anything yet" },
    createBtnLabel: { default: 'create invoice' },
    editValueMethod: { type: Function },
    deleteValueMethod: { type: Function },
    printValueMethod: { type: Function },
    restoreValueMethod: { type: Function },
    toggleActiveMethod: { type: Function },
    buttonValueMethod: { type: Function },
    checkboxMethod: { type: Function },
    cardClasses: { default: null },
    deleteProperty: { default: null },
    selectRow: { default: false },
    dense: { default: false },
    footerData: { default: null },
    tooltipRow: { default: false },
    tooltipTableHeader: { default: null },
    showGroupHeader: { default: false },
    groupHeader: { default: null },
    fixedHeader: { default: true },
    attachmentFileName: {
      default() {
        return this.$i18n.t('attachment')
      },
    },
    getExportData: { type: Function },
    exportData: {
      default() {
        return []
      },
    },
    exportAll: { default: false },
    maxPDFRows: { default: 20 },
    header_slot: { default: false },
    footer_slot: { default: false },
    customize_header_footer: { default: false },
    printStaticData: { default: Object },
    tableHeight: {
      default() {
        return this.$store.state.Settings.windowSize.y - 220
      },
    },
  },
  data: () => ({
    download_loading: false,
    extensions: false,
    fullscreen: false,
    tableLarge: false,
    printLoading: false,
    searchText: '',
    fromDate: null,
    toDate: null,
    date: null,
    today: null,
    selected: [],
    deleteDialog: false,
    dialogDeleteItem: null,
    selectedRow: [],
    htmlToPdfOptions: {
      margin: 2,
    }
  }),
  mounted() {
    this.formatDate();
  },
  methods: {
    exportPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    exportExcel() {
      let header = [...this.header];
      let content = this.exportData.length > 0 ? [...this.exportData] : [...this.data];
      this.extensions = !this.extensions
      let excel_header = []
      header.forEach(head => {
        head.label = head.print_text ? this.$i18n.t(head.print_text) : this.$i18n.t(head.text);
        switch (head.type) {
          case 'float':
            head.value = (row) => {
              let val = row[head.key] || 0
              return this.$global.NumberWithCommas(val.toFixed(2))
            };
            break;
          case 'percentage':
            head.value = (row) => (row[head.key] || 0) + '%';
            break;
          default:
            head.value = head.key
            break;
        }
        if (head.type !== 'actions' && head.type !== 'img' && head.type !== 'switch' && !head.hideInPrint) {
          excel_header.push(head)
        }
      });
      console.log('header', header);
      console.log('excel_header', excel_header);
      if (this.footerData) {
        let footer = [...this.footerData];
        footer.forEach(head => {
          head.label = this.$i18n.t(head.text);
          switch (head.type) {
            case 'float':
              head.value = (row) => {
                let val = row[head.key] || 0
                return this.$global.NumberWithCommas(val.toFixed(2))
              };
              break;
            default:
              head.value = head.key
              break;
          }
        });
        content.push(footer[0])
      }
      let data = [
        {
          sheet: this.$i18n.t(this.attachmentFileName),
          columns: excel_header,
          content: content || [],
        },
      ]

      let settings = {
        fileName: this.pageData ? this.pageData.entityName : this.$i18n.t(this.attachmentFileName),
        extraLength: 3,
        writeMode: "writeFile",
        writeOptions: {},
        RTL: this.$vuetify.rtl,
      }

      xlsx(data, settings);
    },
    formatDate() {
      var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.today = [year, month, day].join('-');
      this.date = [year, month, day].join('-');
    },
    setSearch() {
      this.searchText = this.searchText || null,
        this.fromDate = this.fromDate || null,
        this.toDate = this.toDate || null,
        this.toDate = this.toDate || null,
        this.searchMethod(this.searchText, this.date, this.fromDate, this.toDate);
    },
    changeTableSizeMethod() {
      localStorage.setItem('tableLarge', this.$store.state.tableLarge || false)
      this.$store.state.tableLarge = !this.$store.state.tableLarge;
    },
    deleteValue(status) {
      this.deleteDialog = false;
      if (status == true) {
        if (this.deleteProperty) {
          this.deleteValueMethod(this.dialogDeleteItem[this.deleteProperty])
        } else {

          this.deleteValueMethod(this.dialogDeleteItem.id)
        }
      }
    },
    async getInvoice(id) {
      this.printLoading = true;
      const src = `src="${this.$api.serverUrl}${this.printURL}/${id}?type=image&clientID=${this.$store.state.clientID}"`;
      await this.$api.PrintInvoiceIframe(src, id);
      this.printLoading = false;
    },
  },
};
</script>

<style  lang="scss">
thead {
  tr {
    background-color: #e8e8ee !important;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }
}

tr {
  &:hover {
    background: transparent !important;
  }

  &:nth-of-type(odd) {
    background-color: #fff;

    &:hover {
      background-color: #fff !important;
    }
  }

  &:nth-of-type(even) {
    background-color: #ffffff;

    &:hover {
      background-color: #e8e8ee !important;
    }
  }

  // &:last-of-type {
  //   background-color: #E8E8EE;
  //   &:hover {
  //     background-color: #E8E8EE !important;
  //   }
  // }
}

td,
th {
  border-bottom: 0 !important;
}

tr {
  position: relative;

  .tooltipDetails {
    min-width: 300px;
    filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.1));
    position: relative;
    display: none;
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translate(50%, -50%);
    z-index: 55555;
    font-size: 13px;
    animation: tooltipDetails-animation 1s;
    padding: 2rem;
    border-radius: 15px;
  }

  &:hover {
    .tooltipDetails {
      display: block;
    }
  }

  &:nth-of-type(1) {
    .tooltipDetails {
      top: 7.5rem !important
    }
  }

  &:last-of-type(1) {
    .tooltipDetails {
      bottom: 7.5rem !important
    }
  }
}

@keyframes tooltipDetails-animation {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

