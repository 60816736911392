<template>
  <v-container style="height: 100vh" class="d-flex align-center justify-center bgError">
    <div>
      <v-row align="center" justify="center">
        <v-col cols="auto" class="mt-16">
          <v-icon size="170" color="primary">mdi-update </v-icon>
        </v-col>
        <v-col cols="auto" class="mt-16">
          <h1>{{ $t('new update is downloading') | capitalize}}</h1>
          <p class="my-3">
            {{ $t("it may take a few minutes, please wait") | capitalize}}.
          </p>
          <v-progress-circular :size="40" :width="5" style="margin: 1rem auto; " class="d-block" color="primary"
            indeterminate></v-progress-circular>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped lang="scss">
.bgError {
  background-image: url("../../assets/img/svg/errorBg.svg");
  background-attachment: inherit;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;

  img {
    margin: auto;
    display: block;
    max-height: 200px;
  }
}
</style>

<script>
export default {
  name: "SystemUpdate",
  computed: {},
  data: () => ({
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  created() {

      document.addEventListener('swUpdated', this.swUpdated, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
        document.addEventListener('swReady', this.swReady, { once: true });
  },
  methods: {
    swUpdated(event) {
      console.log('event', event);
      this.registration = event.detail
      // this.$global.ShowAlert('info', this.$i18n.t('New update is available, this page will refresh'));
      this.refreshApp()
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.$router.push('/')
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    swReady() {
      if (this.$route.fullPath == '/system_update') {
        this.$router.push('/')
      }
    }
  },
  mounted() {

  },
};
</script>
