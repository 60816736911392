<template>
  <router-view></router-view>
</template>



<script>

export default {
  name: "ShiftsList",
  mounted() {

  }
};
</script>
