// POS Routes:-
import POSHome from "@/views/POS/POSHome.vue";
import SalesControl from "@/views/POS/Sales/SalesControl.vue";
import RequestEntity from "@/views/POS/Requests/RequestEntity.vue";
import RequestControl from "@/views/POS/Requests/RequestControl.vue";
import PendingRequestsEntity from "@/views/POS/PendingRequests/PendingRequestsEntity.vue";
import PendingRequestsControl from "@/views/POS/PendingRequests/PendingRequestsControl.vue";
import DebtorNoticeEntity from "@/views/POS/DebtorNotice/DebtorNoticeEntity.vue";
import DebtorNoticeControl from "@/views/POS/DebtorNotice/DebtorNoticeControl.vue";
import CreditNoticeEntity from "../views/POS/CreditNotice/CreditNoticeEntity.vue";
import CreditNoticeControl from "../views/POS/CreditNotice/CreditNoticeControl.vue";
import ReportEntity from "../views/POS/Reports/ReportEntity.vue";
import Support from "../views/POS/Support/Support.vue";
import Expenses from "../views/POS/Expenses/Expenses.vue";
import ExpensesEntity from "../views/POS/Expenses/ExpensesEntity.vue";
import ExpensesControl from "../views/POS/Expenses/ExpensesControl.vue";
import BarberBookingEntity from "../views/POS/BarberBooking/BarberBookingEntity.vue";
import FullReportsEntity from "../views/POS/FullReports/FullReportsEntity.vue";
import SalesReportsEntity from "../views/POS/SalesReports/SalesReportsEntity.vue";
import ShiftsControl from "../views/POS/Shifts/ShiftsControl.vue";
import ShiftsEntity from "../views/POS/Shifts/ShiftsEntity.vue";
import Shifts from "../views/POS/Shifts/Shifts.vue";
import BarberBookingCalendar from "@/views/POS/BarberBooking/BarberBookingCalendar.vue";
import BarberBookingControl from "@/views/POS/BarberBooking/BarberBookingControl.vue";

export const POSRoutes = [
  //  Home
  {
    path: "/",
    name: "POSHome",
    component: POSHome,
  },
  // Sales
  {
    path: "/sales",
    name: "SalesControl",
    component: SalesControl,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/sales/pending-request/:id",
    name: "SalesPendingRequestShow",
    component: SalesControl,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/sales/release-booking-invoice/:id",
    name: "ReleaseBookingInvoice",
    component: SalesControl,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/barber-booking",
    name: "BarberBookingEntity",
    component: BarberBookingEntity,
    meta: {
      screen_code: "10-004",
    },
  },
  {
    path: "/barber/barber_booking",
    name: "BarberBookingCalendar",
    component: BarberBookingCalendar,
    meta: {
      screen_code: "10-004",
    },
  },
  {
    path: "/barber/barber_booking/:active_date",
    name: "BarberBookingDateCalendar",
    component: BarberBookingCalendar,
    meta: {
      screen_code: "10-004",
    },
  },
  {
    path: "/barber/barber_booking-control",
    name: "BarberBookingControl",
    component: BarberBookingControl,
    meta: {
      screen_code: "10-004",
    },
  },
  //  Requests
  {
    path: "/requests-list",
    name: "RequestEntity",
    component: RequestEntity,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/requests-control/:id",
    name: "RequestControl",
    component: RequestControl,
    meta: {
      screen_code: "06-008",
    },
  },
  //  Pending Requests
  {
    path: "/pending-requests",
    name: "PendingRequestsEntity",
    component: PendingRequestsEntity,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/pending-requests-control",
    name: "PendingRequestsControl",
    component: PendingRequestsControl,
    meta: {
      screen_code: "06-008",
    },
  },
  // Notice Debtor
  {
    path: "/notice-debtor",
    name: "DebtorNoticeEntity",
    component: DebtorNoticeEntity,
    meta: {
      screen_code: "06-010",
    },
  },
  {
    path: "/notice-debtor-control",
    name: "DebtorNoticeControl",
    component: DebtorNoticeControl,
    meta: {
      screen_code: "06-010",
    },
  },
  // Notice Creditor
  {
    path: "/notice-creditor",
    name: "CreditNoticeEntity",
    component: CreditNoticeEntity,
    meta: {
      screen_code: "06-011",
    },
  },
  {
    path: "/notice-creditor-control",
    name: "CreditNoticeControl",
    component: CreditNoticeControl,
    meta: {
      screen_code: "06-011",
    },
  },
  // Reports
  {
    path: "/reports",
    name: "ReportEntity",
    component: ReportEntity,
    meta: {
      screen_code: "06-008",
    },
  },
  {
    path: "/full-reports",
    name: "FullReportsEntity",
    component: FullReportsEntity,
    meta: {
      screen_code: "06-012",
    },
  },
  {
    path: "/sales-reports",
    name: "SalesReportsEntity",
    component: SalesReportsEntity,
    meta: {
      screen_code: "06-013",
    },
  },
  // Expenses
  {
    path: "/expenses",
    name: "Expenses",
    component: Expenses,
    children: [
      {
        path: "/expenses",
        name: "ExpensesEntity",
        component: ExpensesEntity,
        meta: {
          screen_code: "06-009",
        },
      },
      {
        path: "/expenses-control",
        name: "ExpensesControl",
        component: ExpensesControl,
        meta: {
          screen_code: "06-009",
        },
      },
    ],
  },
  {
    path: "/list_shifts",
    name: "Shifts",
    component: Shifts,
    children: [
      {
        path: "/",
        name: "ShiftsEntity",
        component: ShiftsEntity,
        meta: {
          screen_code: "06-020",
        },
      },
      {
        path: "/list_shifts/:id",
        name: "ShiftsControl",
        component: ShiftsControl,
        meta: {
          screen_code: "06-020",
        },
      },
    ],
  },
  // Support
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      screen_code: "06-008",
    },
  },
];
