<template>
   <section style="height: 100vh ;width:100vw" class="white position-relative d-flex align-center">
      <v-container fluid>
         <v-row align="center" justify="center">
            <v-col cols="auto">
               <img src="@/assets/img/gif/loader.gif" class="d-block ma-auto mb-3" v-if="isLoading" height="200"
                  alt="qawaem">
               <img src="@/assets/img/gif/check.gif" class="d-block ma-auto mb-3" v-if="!isLoading" height="200"
                  alt="qawaem">

               <div class="text-h4 blue9--text text-center mt-3" v-if="isLoading">
                  {{ $t('data is being verified') | capitalize }}
               </div>
               <div class="text-h4 green5--text text-center mt-3" v-if="!isLoading">
                  {{ $t('transferring to the point of sale') | capitalize }}
               </div>
            </v-col>
         </v-row>
      </v-container>
   </section>
</template>
 
 

 
 
<script>

export default {
   name: "AutoLogin",
   computed: {
   },
   watch: {
      //   'form.company_number'() {
      //     this.$store.commit('UPDATE_CLIENT', this.form.company_number || null)
      //   },
   },
   mounted() {
      //   this.$api.LOGOUT(false);

      if (this.$route.query.clientID && this.$route.query.key) {
         this.$store.commit('UPDATE_CLIENT', this.$route.query.clientID || null)
         this.$store.commit('UPDATE_TOKEN', this.$route.query.key || null)
         localStorage.setItem("clientID", this.$route.query.clientID)
         this.isLoading = true;
         this.$api.GET_METHOD(`get_my_profile`).then((response) => {
            console.log(response);
            if (response.check) {
               this.$api.SET_AUTH(response.data);
               this.isLoading = false;
               // setTimeout(() => {
                  this.$router.push('/')
               // }, 1000);
            }
         });
      }
   },
   components: {


   },
   data: () => ({
      isLoading: true
   }),
   methods: {

   },
};
</script>
 