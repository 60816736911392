<template>
  <v-dialog v-model="dialog" persistent max-width="350">
    <template v-slot:activator="{ on, attrs }">
      <v-btn class=" overflow-hidden mb-1" color="gray10" block v-bind="attrs"  v-on="on" depressed rounded-md>
        <img class="mx-1 d-none d-lg-block" height="32" src="@/assets/img/svg/cashier.svg" alt="Qawaem-POS" />
        <!-- <span class="d-md-block d-none" v-if="!sellar">{{ $t('change agent') }}</span>-->
        <span class="caption font-weight-bold" v-if="sellar">
          {{ sellar.name }}</span>
      </v-btn>
    </template>
    <v-card relative>
      <v-btn absolute class="mt-2" :style="$vuetify.rtl == true ? 'left: 0' : 'right: 0'" small fab
        color="grey lighten-1" text @click="dialog = false">
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h6">{{ $t('change agent') }}</v-card-title>
      <v-divider></v-divider>
      <v-form class="pa-5">
        <v-col cols="12" sm="12">
          <v-btn-toggle v-model="toggle_sellar" tile color="gray lighten-5" class="d-flex mb-2 justify-center" group>
            <v-row>
              <v-col cols="12" v-for="(agent, index) in sales_agents" :key="index">
                <v-btn v-on:click="sellar = agent" :value="agent" class="rounded ma-1 text-right" color="gray lighten-5"
                  elevation="0" style="width: 100%; text-align: start">
                  <v-icon color="blue accent-4" :disabled="sellar != agent" left>mdi-check-circle</v-icon>
                  {{ agent.name }}
                </v-btn>
              </v-col>
              <v-col cols="12" v-if="sales_agents.length == 0">
                <v-alert class="d-block ma-auto" dense outlined text type="info">{{ $t('No data available') }}</v-alert>
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" class="py-4">
          <v-btn color="success" large @click="returnData" class="shadow" style="width: 100%; text-align: start">{{
              $t('add')
          }}</v-btn>
        </v-col>
      </v-form>
    </v-card>
  </v-dialog>
</template>



<style scoped lang="scss">

</style>



<script>
export default {
  name: "SalesAgentModal",
  props: {
    backValueMethod: { type: Function },
    sales_agents: { default: [] },
    sellar: { default: {} },
  },
  computed: {
    isRTL() {
      return this.$store.state.Settings.isRTL;
    },
    translation() {
      return this.$store.state.Settings.translation;
    },
  },
  watch: {
    '$store.state.Settings.key_clicked'() {
      switch (this.$store.state.Settings.key_clicked) {
        case 'F7':
          this.dialog = !this.dialog
          break;
        case 'Escape':
          this.dialog = false
          break;
        default:
          break;
      }
    },
  },
  mounted() {

  },
  data() {
    return {
      dialog: false,
      toggle_sellar: 1,
    };
  },
  methods: {
    returnData() {
      this.backValueMethod(this.sellar);
      this.dialog = false
    },
  },
};
</script>
